import React from "react";

const FAQ = () => {
  return (
    <main>
      <section className="position-relative bg-gradient-tint">
        <div className="container position-relative pt-14 pb-9">
          <div className="row pt-lg-9 pb-lg-4">
            <div className="col-lg-10 mx-auto text-center">
              <h1 className="display-4 mb-3">FAQ</h1>
              <p className="lead mb-0">Frequently asked questions</p>
            </div>
          </div>
          {/* <!--/.row--> */}
        </div>
        {/* <!--/.content--> */}
      </section>
      <section className="position-relative">
        <div className="container py-9 py-lg-11">
          <div className="row mb-9 mb-lg-11">
            <div className="col-lg-8 col-xl-7 mx-auto">
              <h2 data-aos="fade-up">Global Capability Center (GCC)</h2>
              <div
                data-aos="fade-up"
                className="width-7x border-top border-2 border-primary mb-5"
              ></div>
              {/* <!--Faq box--> */}
              <div className="p-4 mb-3 border rounded-3" data-aos="fade-up">
                <h5 className="mb-3">What is a Global Capability Center?</h5>
                <p className="mb-0">
                  Global Capability Centers(GCCs) are centers located in
                  geographies other than the headquarters, that enable investing
                  organizations to augment their capabilities and capacities to
                  support operations, foster innovation and achieve efficiency
                  across a range of Key Performance Metrics.
                </p>
              </div>

              {/* <!--Faq box--> */}
              <div
                className="p-4 mb-3 border rounded-3"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h5 className="mb-3">What is GCC in IT industry?</h5>
                <p className="mb-0">
                  GCCs in the IT industry are primarily invested for reducing
                  the total cost of ownership of a product, improving customer
                  experience across touch points, increasing productivity and
                  efficiency across business processes.
                </p>
              </div>

              {/* <!--Faq box--> */}
              <div className="p-4 mb-3 border rounded-3" data-aos="fade-up">
                <h5 className="mb-3">
                  What are the benefits of global capability centers?
                </h5>

                <ul className="list-style-dot">
                  <li className="mb-2">Access to global talent pools.</li>
                  <li className="mb-2">Cost arbitrage.</li>
                  <li className="mb-2">
                    Improved customer experience (24/7 customer care).
                  </li>
                  <li className="mb-2">
                    Business Continuity Planning and Faster Disaster Recovery.
                  </li>
                  <li className="mb-2">Efficiency in business process KPIs.</li>
                </ul>
              </div>
              {/* <!--Faq box--> */}
              <div className="p-4 mb-3 border rounded-3" data-aos="fade-up">
                <h5 className="mb-3">
                  What is the vision of the Global Capability Center?
                </h5>
                <p className="mb-0">
                  The vision of a GCC is to augment capabilities and capacities
                  of the investing organization in any form that it deems fit to
                  improve its overall business outcomes.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-7 mb-lg-9">
            <div className="col-lg-8 col-xl-7 mx-auto">
              <h2 data-aos="fade-up">Center of Excellence (CoE)</h2>
              <div
                data-aos="fade-up"
                className="width-7x border-top border-2 border-primary mb-5"
              ></div>
              {/* <!--Faq box--> */}
              <div className="p-4 mb-3 border rounded-3" data-aos="fade-up">
                <h5 className="mb-3">What defines a Center of Excellence?</h5>
                <p className="mb-0">
                  A Center of Excellence (CoE) is a team of skilled knowledge
                  workers whose mission is to provide the organization they work
                  for with best practices around a particular area of interest.
                  The concept originated from special interest groups in lean
                  manufacturing.
                </p>
              </div>

              {/* <!--Faq box--> */}
              <div
                className="p-4 mb-3 border rounded-3"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h5 className="mb-3">
                  What is the new term for center of excellence?
                </h5>
                <p className="mb-0">
                  Also known as GIC (Global In Sourced center or Global In House
                  center).
                </p>
              </div>

              {/* <!--Faq box--> */}
              <div className="p-4 mb-3 border rounded-3" data-aos="fade-up">
                <h5 className="mb-3">
                  What are the stages of center of excellence?
                </h5>
                <p className="mb-4">
                  CoEs are primarily setup for 3 purposes - Run the business,
                  Grow the business or Transform the business. The first helps
                  in bringing in efficiency from a cost perspective, the second
                  augments capacities and capabilities and the third involves in
                  making the organization future ready.
                </p>
              </div>
              {/* <!--Faq box--> */}
              <div className="border p-4 rounded-3" data-aos="fade-up">
                <h5 className="mb-3">
                  What services do centers of excellence typically offer?
                </h5>

                <ul className="list-style-dot">
                  <li className="mb-2">
                    Expand capacities in a lower cost geography to either expand
                    customer service to 24/7 operations, lower TCO for
                    product/service, efficiency in business process
                    management(BPM)
                  </li>
                  <li className="mb-2">
                    Expanding capabilities by fostering innovation across value
                    chains.
                  </li>
                  <li className="mb-2">
                    Investing in digital transformation initiatives to realize
                    improved KPIs across supply chains.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-xl-7 mx-auto">
              <div className="border-top pt-9" data-aos="fade-up">
                <h5>Still have questions? </h5>
                <p className="mb-0">
                  We're here to help! Please email{" "}
                  <a href="mailto:giri@gpracta.com" className="link-underline">
                    giri@gpracta.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FAQ;
