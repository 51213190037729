import React, { useState } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    to_name: "",
    from_name: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = "service_sc3ghwp";
    const templateID = "template_9tso82j";
    const userID = "Kj6wglAiqYQAO9pbY"; // Replace with your actual public key

    emailjs.send(serviceID, templateID, formData, userID).then(
      (response) => {
        console.log("Email sent successfully!", response.status, response.text);
        alert("Email sent successfully!");
      },
      (error) => {
        console.error("Failed to send email.", error);
        alert("Failed to send email.");
      }
    );

    // Clear form after submission
    setFormData({
      to_name: "",
      from_name: "",
      message: "",
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <link rel="canonical" href="/contact-us" />
      </Helmet>

      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>

      <main>
        <section className="bg-default">
          <div className="container pt-14 pb-9 pb-lg-12">
            <div className="row pt-lg-7">
              <div className="col-xl-9">
                <ol className="breadcrumb mb-3">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Contact Info</li>
                </ol>
                <h1 className="display-4 mb-3">Get in touch with us</h1>
                <p className="mb-0 lead pe-lg-8">
                  Do you have questions about our services or looking to talk to
                  a specific team member? Use the contact form below and we will
                  get back to you.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative">
          <div className="container py-9 py-lg-11">
            <div className="row">
              <div className="col-md-8 col-lg-7 mb-7 mb-md-0 me-auto">
                <div className="position-relative">
                  <h2>Contact form</h2>
                  <p className="mb-3 w-lg-75">
                    Use the contact form if you have questions about our
                    products. Our sales team will be happy to help you:
                  </p>
                  <div className="width-7x pt-1 bg-primary mb-5"></div>
                  {/* <!-- Contacts Form --> */}

                  <form
                    id="contactForm"
                    // action="assets/contact/send_mail.php"
                    // method="post"
                    // role="form"
                    className="needs-validation mb-5 mb-lg-7"
                    // noValidate\
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      {/* <!-- Input --> */}
                      <div className="col-sm-6 mb-3">
                        <label className="form-label" htmlFor="name">
                          Your name
                        </label>
                        <input
                          type="text"
                          name="from_name"
                          value={formData.from_name}
                          onChange={handleChange}
                          required
                          className="form-control"
                          id="name"
                          placeholder="Benjamin Franklin"
                        />
                      </div>
                      {/* <!-- End Input --> */}

                      {/* <!-- Input --> */}
                      <div className="col-sm-6 mb-3">
                        <label className="form-label" htmlFor="email">
                          Your email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="ben@usa.com"
                          aria-label="ben@usa.com"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter a valid email address
                        </div>
                      </div>

                      <div className="w-100"></div>

                      {/* <!-- Input --> */}

                      {/* <!-- Services --> */}
                      <div className="col-sm-12 mb-3">
                        <label className="form-label" htmlFor="subject">
                          Subject
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                          id="subject"
                          placeholder="Web Design"
                        />
                      </div>
                      {/* <!-- End Input --> */}
                    </div>

                    {/* <!-- Message --> */}
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        placeholder="Hi there...."
                      ></textarea>
                      <div className="invalid-feedback">
                        Please enter a message in the textarea.
                      </div>
                    </div>

                    <div className="d-md-flex justify-content-between align-items-center">
                      <p className="small mb-4 text-body-secondary mb-md-0">
                        We'll get back to you in 1-2 business days.
                      </p>
                      <input
                        type="submit"
                        name="submit"
                        value="Submit message"
                        id="sendBtn"
                        className="btn btn-lg btn-primary"
                      />
                    </div>
                  </form>
                  {/* <!-- End Contacts Form --> */}

                  {/* <!--Card--> */}
                  <div
                    className="px-4 py-7 px-lg-5 py-lg-7 border border-2 rounded-3 position-relative"
                    data-aos="fade-up"
                  >
                    <div className="position-relative">
                      <h3 className="mb-4">Need customer support?</h3>
                      <p className="w-lg-90 mb-5 lead">
                        Are you and existing customer and looking to get in
                        touch for customer support, Click below to send us a
                        message.
                      </p>
                      <div className="width-6x pt-1 bg-success mb-5"></div>
                      <a
                        href="mailto:support@gpracta.com"
                        className="btn btn-outline-primary btn-rise"
                      >
                        <div className="btn-rise-bg bg-primary"></div>
                        <div className="btn-rise-text">Customer support</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div data-aos="fade-up">
                  <h4 className="mb-0">Our Offices</h4>
                  <div
                    className="border-top border-2 border-secondary mb-4 mt-2"
                    data-aos="fade-up"
                  ></div>
                </div>
                <div data-aos="fade-up">
                  <h5>USA</h5>
                  <div className="position-relative">
                    <p>
                      <strong>Dallas Metroplex, Texas </strong>
                      <br />
                      726 Sleepy Creek Dr
                      <br /> Frisco, TX 75036
                    </p>
                    <p>
                      Phone:&nbsp;+1 712-214-3811 <br />
                      Website:{" "}
                      <a rel="noopener" href="#!">
                        www.gpracta.com
                      </a>
                      <br />
                      Email:{" "}
                      <a rel="noopener" href="#!">
                        sales.us@gpracta.com
                      </a>
                    </p>
                  </div>
                </div>
                <div
                  className="border-top border-2 border-secondary my-4 my-lg-5"
                  data-aos="fade-up"
                ></div>
                <div data-aos="fade-up" data-aos-delay="100">
                  <h5>India</h5>
                  <div className="position-relative">
                    <p>
                      <strong>Bangalore </strong>
                      <br />
                      #683/A, 2nd Floor, 15th Cross,
                      <br />
                      13th Main, J P Nagar 2nd Phase, Bangalore 560078
                    </p>
                    <p>
                      Phone:&nbsp;+91 9686515553
                      <br />
                      Website:{" "}
                      <a rel="noopener" href="https://gpracta.com">
                        www.gpracta.com
                      </a>
                      <br />
                      Email:{" "}
                      <a rel="noopener" href="#!">
                        support@gpracta.com
                      </a>
                    </p>
                  </div>
                </div>
                <div
                  className="border-top border-2 border-secondary my-4 my-lg-5"
                  data-aos="fade-up"
                ></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ContactUs;
