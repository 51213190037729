import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const baseURL =
  "https://search-gpractablogs-va7ffba26ewmwmeg273fser5ee.us-east-1.es.amazonaws.com";
const username = "admin";
const password = "Gpracta123$";

const EditNews = () => {
  const [formData, setFormData] = useState({
    source: "",
    title: "",
    blog_link: "",
    posted_date: new Date().toLocaleDateString("en-GB"),
    expiry_date: "",
  });
  let { id } = useParams();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/news/_doc/${id}`,

        formData,
        {
          auth: {
            username: username,
            password: password,
          },
        }
      );
      console.log("Document added:", response.data);
      // Reset form after successful submission
      setFormData({
        source: "",
        title: "",
        blog_link: "",
        posted_date: new Date().toLocaleDateString("en-GB"),
        expiry_date: "",
      });

      window.location = "/admin/manage-blogs";
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit News</title>
      </Helmet>
      <div className="container-lg">
        {/* <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>

        <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div> */}
        <br />
        <h4 style={{ textAlign: "center" }}>Edit News </h4>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Source{" "}
            </label>
            <input
              type="text"
              name="source"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Source"
              value={formData.source}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Title"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Link
            </label>
            <input
              type="text"
              name="blog_link"
              value={formData.blog_link}
              placeholder="Link"
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Upload Date{" "}
            </label>
            <input
              type="text"
              name="posted_date"
              value={formData.posted_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Upload Date"
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Expiry Date{" "}
            </label>
            <input
              type="date"
              name="expiry_date"
              value={formData.expiry_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Expiry Date"
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Expiry Date{" "}
            </label>
            <input
              type="date"
              name="posted_date"
              value={formData.posted_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Upload Date"
              onChange={handleChange}
              required
            />
          </div> */}

          {/* <div className="mb-3">
            <label for="formFile" className="form-label">
              Thumbnail
            </label>
            <input className="form-control" type="file" id="formFile" />
          </div> */}
          <button
            type="submit"
            className="btn btn-success"
            style={{ float: "right" }}
          >
            Update News
          </button>
        </form>
      </div>
    </>
  );
};

export default EditNews;
