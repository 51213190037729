import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Required meta tags --> */}

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="assets/img/favicon.ico" type="image/ico" />

        {/* <!--Box Icons--> */}
        <link
          rel="stylesheet"
          href="assets/fonts/boxicons/css/boxicons.min.css"
        />

        {/* <!--Iconsmind Icons--> */}
        <link rel="stylesheet" href="assets/fonts/iconsmind/iconsmind.css" />
        {/* <!--Choices select--> */}
        <link
          rel="stylesheet"
          href="assets/vendor/node_modules/css/choices.min.css"
        />

        {/* <!--Swiper--> */}
        <link
          rel="stylesheet"
          href="assets/vendor/node_modules/css/swiper-bundle.min.css"
        />
        {/* <!-- Aos Animations CSS --> */}
        <link href="assets/vendor/node_modules/css/aos.css" rel="stylesheet" />

        {/* <!-- Google fonts CSS --> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=PT+Serif:ital@0;1&display=swap"
          rel="stylesheet"
        />
        {/* <!-- Main CSS --> */}
        <link href="assets/css/theme-green.min.css" rel="stylesheet" />

        <title>
          GPracta - Scale your team with a Global Capability Center in India
        </title>
      </Helmet>
      <header className="z-fixed header-absolute-top header-sticky sticky">
        <nav className="navbar navbar-expand-lg py-3 navbar-light">
          <div className="container position-relative">
            {/* Brand Logo */}
            <a className="navbar-brand" href="/">
              <img
                src="assets/img/logo/logo.png"
                alt=""
                className="img navbar-brand-light"
              />
              <img
                src="assets/img/logo/logo.png"
                alt=""
                className="img navbar-brand-dark"
              />
            </a>
            <div className="d-flex align-items-center navbar-no-collapse-items order-lg-last">
              <button
                className="navbar-toggler order-last"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainNavbarTheme"
                aria-controls="mainNavbarTheme"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i></i>
                </span>
              </button>
            </div>
            {/* Navigation Links */}
            <div className="collapse navbar-collapse" id="mainNavbarTheme">
              <ul className="navbar-nav me-lg-3 ms-lg-auto">
                <li className="nav-item">
                  <a href="/" className="nav-link active">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/our-story" className="nav-link">
                    Our Story
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    href="/global-capability-centers"
                    data-bs-toggle="dropdown"
                    className="nav-link dropdown-toggle"
                  >
                    Services
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a
                      href="/global-capability-centers"
                      className="active dropdown-item"
                    >
                      <img
                        src="assets/img/country/in.svg"
                        className="me-2"
                        width="20"
                        alt=""
                      />
                      <small>Global Capability Center</small>
                    </a>
                    <a href="/center-of-excellence" className="dropdown-item">
                      <img
                        src="assets/img/country/in.svg"
                        className="me-2"
                        width="20"
                        alt=""
                      />
                      <small>Center of Excellence</small>
                    </a>
                    <a
                      href="/executive-search"
                      className="dropdown-item"
                      hidden
                    >
                      <img
                        src="assets/img/country/us.svg"
                        className="me-2"
                        width="20"
                        alt=""
                      />
                      <small>Executive Search & Staffing</small>
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a href="/blogs" className="nav-link">
                    Insights
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/contact-us" className="nav-link">
                    Contact Info
                  </a>
                </li>
                {sessionStorage.username === undefined ? (
                  <></>
                ) : (
                  <li className="nav-item dropdown">
                    <a
                      href="/admin"
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      {sessionStorage.username}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="/admin">
                        Profile
                      </a>
                      <a
                        className="dropdown-item"
                        href="/"
                        onClick={() => {
                          sessionStorage.removeItem("username");
                          sessionStorage.removeItem("login");
                        }}
                      >
                        Logout
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
