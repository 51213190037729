import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
// import TextEditor from "../utils/TextEditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const baseURL = process.env.react_app_opensearch_url;
const username = process.env.react_app_opensearch_username;
const password = process.env.react_app_opensearch_password;

const AddGPractaBlog = () => {
  const [formData, setFormData] = useState({
    source: "GPracta",
    title: "",
    content: "",
    posted_date: `${new Date().toLocaleDateString("en-GB")}`,
    expiry_date: "",
    category: "",
    content_excerpt: "",
    author: "",
    logo: "",
    author_avatar: "",
  });

  const handleChange = (e, html) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/blog/_doc`,

        formData,
        {
          auth: {
            username: username,
            password: password,
          },
        }
      );
      console.log("Document added:", response.data);
      // Reset form after successful submission
      setFormData({
        source: "GPracta",
        title: "",
        content: "",
        posted_date: `${new Date().toLocaleDateString("en-GB")}`,
        expiry_date: "",
        category: "",
        content_excerpt: "",
        author: "",
        logo: "",
        author_avatar: "",
      });

      window.location = "/blogs";
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Blog</title> {formData.content}
        <link rel="canonical" href="/blogs" />
      </Helmet>
      <div className="container-lg">
        <br />
        <h4 style={{ textAlign: "center" }}>Add Blog </h4>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Title"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Category
            </label>
            <input
              type="text"
              name="category"
              value={formData.category}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Category"
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Content Excerpt
            </label>
            <input
              type="text"
              name="content_excerpt"
              value={formData.content_excerpt}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Content Excerpt"
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Author
            </label>
            <input
              type="text"
              name="author"
              value={formData.author}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Author"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Content
            </label>
            {/* <input
              type="text"
              name="content"
              value={formData.content}
              placeholder="Content"
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={handleChange}
              required
            ></input> */}
            <ReactQuill
              theme="snow" // Specify theme
              type="html"
              name="content"
              // className="form-control"
              // id="exampleFormControlInput1"
              placeholder="Type Here...."
              value={formData.content}
              onChange={handleChange}
              style={{ height: 400 }}
            />
          </div>
          <br />
          <br />
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Source{" "}
            </label>
            <input
              type="text"
              name="source"
              value={formData.source}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Upload Date"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Upload Date{" "}
            </label>
            <input
              type="text"
              name="posted_date"
              value={formData.posted_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Upload Date"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Expiry Date{" "}
            </label>
            <input
              type="date"
              name="expiry_date"
              value={formData.expiry_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Expiry Date"
              onChange={handleChange}
              required
            />
          </div>
          <div class="mb-3">
            <label for="formFile" class="form-label">
              Blog Image
            </label>
            <input class="form-control" type="file" id="formFile" />
          </div>

          <div class="mb-3">
            <label for="formFile" class="form-label">
              Author Avatar
            </label>
            <input class="form-control" type="file" id="formFile" />
          </div>
          {/* <div className="mb-3">
            <label for="formFile" className="form-label">
              Thumbnail
            </label>
            <input className="form-control" type="file" id="formFile" />
          </div> */}
          <button
            type="submit"
            className="btn btn-success"
            style={{ float: "right" }}
          >
            Save Blog
          </button>
          <br />
          <br />
          <br />
          <br />
        </form>
      </div>
    </>
  );
};

export default AddGPractaBlog;
