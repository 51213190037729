import React, { useContext } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import AuthContext from "../AuthContext";
import { Helmet } from "react-helmet";

const GoogleOAuth = () => {
  const { login } = useContext(AuthContext);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
        <link rel="canonical" href="/blogs" />
      </Helmet>

      <div
        className="card text-center"
        style={{ width: "50%", marginLeft: "25%", marginTop: "12.5%" }}
      >
        <div className="card-header">GPracta Logo</div>
        <div className="card-body">
          <h5 className="card-title">Login to GPracta</h5>
          <div style={{ width: "100%", marginLeft: "35%" }}>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                // console.log(credentialResponse);
                var credentialResponseDecoded = jwtDecode(
                  credentialResponse.credential
                );
                // console.log(credentialResponseDecoded);

                if (
                  credentialResponseDecoded.email === "krishnav@gpracta.com"
                ) {
                  sessionStorage.setItem(
                    "login",
                    `${credentialResponseDecoded.email}`
                  );
                  sessionStorage.setItem(
                    "username",
                    `${credentialResponseDecoded.given_name}`
                  );

                  login(credentialResponseDecoded);
                  window.location = "/admin";
                } else {
                  window.location = "/pagenotfound";
                }
              }}
              onError={() => {
                console.log("Login Failed");
              }}
              // style={{ marginLeft: "70%" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleOAuth;
