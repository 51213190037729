import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

const OurStory = () => {
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true, // Enable swipe to slide
    arrows: false,
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Story</title>
        <link rel="canonical" href="/our-story" />
      </Helmet>
      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>

      <main>
        <section className="position-relative bg-gradient-tint">
          <div className="container position-relative pt-14 pb-9">
            <div className="row pt-lg-9 pb-lg-4">
              <div className="col-lg-10 mx-auto text-center">
                <h1 className="display-4 mb-3">Our Story</h1>
                <p className="lead mb-0">
                  Empowering SMEs with tailored Global Capability Centers for
                  seamless, scalable, and successful international operations.
                </p>
              </div>
            </div>
            {/* <!--/.row--> */}
          </div>
          {/* <!--/.content--> */}
        </section>
        {/* <!--page-hero--> */}
        <section className="position-relative">
          <div className="container pt-12 pb-7 pb-lg-9 position-relative">
            <div className="row pt-9 pt-lg-6  align-items-center">
              <h1>The Journey of GPracta</h1>
              <p>
                GPracta was founded in 2005 with a vision to bridge the gap
                between U.S. companies and the vast pool of talent and resources
                in India. Recognizing the growing need for businesses to expand
                their capabilities and streamline operations, we set out to
                create a seamless pathway for small and medium enterprises
                (SMEs) to establish their own GCCs in India.
              </p>
              <p>
                Our founders, driven by a passion for innovation and efficiency,
                saw the potential for GCCs to transform business operations.
                They embarked on a mission to provide tailored solutions that
                address the unique challenges faced by SMEs, ensuring they reap
                the benefits of a globalized workforce without the complexities
                often associated with international expansion.
              </p>
              <p>
                Over the years, GPracta has grown from a small consultancy to a
                trusted partner for numerous SMEs across various industries. Our
                team of experts has successfully guided companies through every
                step of setting up and managing their GCCs, from initial
                planning and strategy to implementation and ongoing support.
              </p>
              <p>
                Our commitment to excellence and customer satisfaction has been
                the cornerstone of our success. We pride ourselves on our
                personalized approach, understanding that each business has
                distinct needs and goals. By staying at the forefront of
                industry trends and technological advancements, we continuously
                refine our services to deliver exceptional value to our clients.
              </p>
              <p>
                Today, GPracta stands as a leader in the GCC industry,
                empowering businesses to achieve their full potential. As we
                look to the future, we remain dedicated to our mission of
                enabling SMEs to thrive in a globalized world, fostering
                innovation, and driving sustainable growth.
              </p>
              <p>
                Join us on this journey and discover how GPracta can help your
                business unlock new opportunities and achieve unparalleled
                success through the power of Global Capability Centers.
              </p>
            </div>
          </div>
          {/* <!--Hero image--> */}
        </section>
        <section className="overflow-hidden position-relative">
          <div className="container py-9 py-lg-11 position-relative z-1">
            <div className="col-12" data-aos="fade-left" data-aos-delay="100">
              <h2 className="mb-0 display-6">Our Growth Milestones</h2>
            </div>
            <br />
            <br />
            <Slider {...settings}>
              <div>
                <div className="pe-5 pb-3 pe-lg-7">
                  {/* <!--Time--> */}
                  <p className="text-body-secondary">At Present</p>
                  {/* <!--Year--> */}
                  <h2 className="fs-1">2024</h2>
                </div>
                {/* <!--Divider line--> */}
                <div className="swiper-divider-line bg-primary position-relative w-100 my-4"></div>
                {/* <!--Text--> */}
                <div className="pe-7 pt-3 pe-lg-7">
                  <h5>Scaling Horizontally & Vertically</h5>
                  <p>
                    Domain & Skills - Building teams across line functions for
                    multiple clients.
                  </p>
                </div>
              </div>
              <div>
                <div className="pe-5 pb-3 pe-lg-7">
                  {/* <!--Time--> */}
                  <p className="text-body-secondary">June</p>
                  {/* <!--Year--> */}
                  <h2 className="fs-1">2017</h2>
                </div>
                {/* <!--Divider line--> */}
                <div className="swiper-divider-line bg-primary bg-opacity-75 position-relative w-100 my-4"></div>
                {/* <!--Text--> */}
                <div className="pe-7 pt-3 pe-lg-7">
                  <h5>US Office - Multi shore capability</h5>
                  <p>
                    Providing services nearshore for Fortune 500 firms in
                    addition to GCCs
                  </p>
                </div>
              </div>
              <div>
                <div className="pe-5 pb-3 pe-lg-7">
                  {/* <!--Time--> */}
                  <p className="text-body-secondary">March</p>
                  {/* <!--Year--> */}
                  <h2 className="fs-1">2014</h2>
                </div>
                {/* <!--Divider line--> */}
                <div className="swiper-divider-line bg-primary bg-opacity-50 position-relative w-100 my-4"></div>
                {/* <!--Text--> */}
                <div className="pe-7 pt-3 pe-lg-7">
                  <h5>Dedicated GCC Setup</h5>
                  <p>
                    GCC setup from groundup with 70+ FTEs, scaled to 2000+ FTEs
                  </p>
                </div>
              </div>
              <div>
                <div className="pe-5 pb-3 pe-lg-7">
                  {/* <!--Time--> */}
                  <p className="text-body-secondary">July</p>
                  {/* <!--Year--> */}
                  <h2 className="fs-1">2013</h2>
                </div>
                {/* <!--Divider line--> */}
                <div className="swiper-divider-line bg-primary bg-opacity-25 position-relative w-100 my-4"></div>
                {/* <!--Text--> */}
                <div className="pe-7 pt-3 pe-lg-7">
                  <h5>Hosted Model of GCC Inception</h5>
                  <p>25+ FTEs (IT and Ops) for Private Equity backed Ed-Tech</p>
                </div>
              </div>
              <div>
                <div className="pe-5 pb-3 pe-lg-7">
                  {/* <!--Time--> */}
                  <p className="text-body-secondary">April</p>
                  {/* <!--Year--> */}
                  <h2 className="fs-1">2005</h2>
                </div>
                {/* <!--Divider line--> */}
                <div className="swiper-divider-line bg-primary bg-opacity-10 position-relative w-100 my-4"></div>
                {/* <!--Text--> */}
                <div className="pe-7 pt-3 pe-lg-7">
                  <h5>Founded as an Executive Search Firm</h5>
                  <p>
                    Setup the office of the CIO for India salt to steel
                    conglomerate.
                  </p>
                </div>
              </div>
              {/* Add more slides here */}
            </Slider>
          </div>
        </section>

        {/*  */}

        <section className="position-relative">
          <div className="container py-9 py-lg-11 position-relative z-1">
            <div className="col-12">
              <h2 className="mb-7 display-6 text-center">Our founders</h2>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-lg-4 col-sm-6 col-10 mb-4 mb-lg-0"
                data-aos="fade-up"
                data-aos-duration="350"
              >
                <div className="card-over card-hover text-center card border-0 shadow-sm overflow-hidden hover-shadow-lg">
                  <div className="card-image mx-auto d-block overflow-hidden position-relative">
                    <img
                      src="assets/img/team/giri.jpg"
                      alt=""
                      className="img-fluid"
                    />

                    {/* <!--Image divider--> */}
                    <svg
                      className="position-absolute start-0 bottom-0 mb-n2"
                      style={{ color: "var(--bs-body-bg)" }}
                      preserveAspectRatio="none"
                      width="100%"
                      height="30"
                      viewBox="0 0 1200 160"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 16L67 29.3333C133 42.6667 267 69.3333 400 82.6667C533 96 667 96 800 80C933 64 1067 32 1133 16L1200 0V160H1133C1067 160 933 160 800 160C667 160 533 160 400 160C267 160 133 160 67 160H0V16Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  {/* <!--/.image and overlay--> */}
                  <div className="card-body">
                    <h5 className="mb-1">Giridhar Mudbidri</h5>
                    <p className="mb-0">Founder & CEO</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 col-10 mb-4 mb-lg-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="card-over card-hover text-center card border-0 shadow-sm overflow-hidden hover-shadow-lg">
                  <div className="card-image mx-auto d-block overflow-hidden position-relative">
                    <img
                      src="assets/img/team/pallavi.jpg"
                      alt=""
                      className="img-fluid"
                    />

                    {/* <!--/.overlay-->
                                    <!--Image divider--> */}
                    <svg
                      className="position-absolute start-0 bottom-0 mb-n2"
                      style={{ color: "var(--bs-body-bg)" }}
                      preserveAspectRatio="none"
                      width="100%"
                      height="30"
                      viewBox="0 0 1200 160"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 16L67 29.3333C133 42.6667 267 69.3333 400 82.6667C533 96 667 96 800 80C933 64 1067 32 1133 16L1200 0V160H1133C1067 160 933 160 800 160C667 160 533 160 400 160C267 160 133 160 67 160H0V16Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  {/* <!--/.image and overlay--> */}
                  <div className="card-body">
                    <h5 className="mb-1">Pallavi Pushpalata</h5>
                    <p className="mb-0">Co-Founder & Head of Operations</p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-sm-6 col-10"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="card-over text-center card border-0 shadow-sm overflow-hidden hover-shadow-lg h-100">
                  <div className="pb-5 pt-4 bg-success-subtle position-relative overflow-hidden mb-4">
                    <div className="width-8x height-8x bg-primary flex-center text-white rounded-circle shadow-lg">
                      <i className="fs-3 bx bx-plus"></i>
                    </div>
                    {/* <!--Image divider--> */}
                    <svg
                      className="position-absolute start-0 bottom-0 mb-n2"
                      style={{ color: "var(--bs-body-bg)" }}
                      preserveAspectRatio="none"
                      width="100%"
                      height="30"
                      viewBox="0 0 1200 160"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 16L67 29.3333C133 42.6667 267 69.3333 400 82.6667C533 96 667 96 800 80C933 64 1067 32 1133 16L1200 0V160H1133C1067 160 933 160 800 160C667 160 533 160 400 160C267 160 133 160 67 160H0V16Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  {/* <!--/.image and overlay--> */}
                  <div className="card-body">
                    <h5 className="mb-3">Want to join our awesome team?</h5>
                    <p className="mb-4 w-75 mx-auto lh-sm">
                      We are always looking for new talent to join out team.
                      Click below to send your most current resume and apply.
                    </p>
                    <a
                      href="mailto:ta@gpracta.com"
                      // class="link-underline fs-6 fw-semibold"
                    >
                      Send your Resume
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default OurStory;
