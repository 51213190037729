import React from "react";
import { Helmet } from "react-helmet";

const ExecutiveSearch = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Executive Search</title>
        <link rel="canonical" href="/executive-search" />
      </Helmet>

      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>

      <main>
        <section className="position-relative bg-gradient-tint">
          <div className="container position-relative pt-14 pb-9">
            <div className="row pt-lg-9 pb-lg-4">
              <div className="col-lg-10 mx-auto text-center">
                <h1 className="display-4 mb-3">Executive Search</h1>
                <p className="lead mb-0">
                  Find the perfect talent to drive your success with GPracta's
                  expert Executive Search services.
                </p>
              </div>
            </div>
            {/* <!--/.row--> */}
          </div>
          {/* <!--/.content--> */}
        </section>
        <section className="position-relative">
          <div className="container pt-6 pb-7 pb-lg-9 position-relative">
            <div className="row pt-9 pt-lg-6  align-items-center">
              <p>
                At GPracta, our Executive Search service is designed to go
                beyond traditional recruitment methods to identify and secure
                top-tier leadership talent for your organization. We understand
                that finding the right leaders is critical for driving your
                company's strategic vision and achieving long-term success. Our
                unique approach to executive search combines deep industry
                insights, advanced search techniques, and a personalized touch
                to ensure we find the best candidates who align with your
                company’s culture and goals.
              </p>
            </div>
          </div>
          {/* <!--Hero image--> */}
        </section>
        {/* <!--begin:feature image section--> */}
        <section className="position-relative">
          <div className="container position-relative py-9 py-lg-8">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-lg-6 mb-5 mb-lg-0 order-lg-last"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="position-relative p-2">
                  {/* <!--background-parallax-shape--> */}
                  <div
                    className="rellax bg-warning width-9x height-9x rounded-circle position-absolute end-0 top-0"
                    data-rellax-percentage="0.95"
                    data-rellax-speed="2"
                  ></div>
                  {/* <!--background-parallax-shape--> */}
                  <div
                    className="rellax bg-danger width-5x height-5x rounded-circle position-absolute bottom-0 mb-5 start-0"
                    data-rellax-percentage="0.1"
                    data-rellax-speed="-1"
                  ></div>

                  {/* <!--Shape Image with mask--> */}
                  <div className="bg-mask">
                    <img
                      src="assets/img/960x900/exec-search.png"
                      className="mask-squircle mask-image"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 order-lg-1 position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {/* <!--Heading--> */}
                <h2 className="mb-4 display-6">
                  Why Choose us for your{" "}
                  <span className="text-gradient">Talent Search </span> needs?
                </h2>

                {/* <!--Text--> */}
                <p className="mb-5">
                  We deliver exceptional results through a personalized
                  approach, deep industry expertise, and a proven track record
                  of long-term client success. Our commitment to excellence
                  ensures that we find the perfect talent to drive your
                  organization's growth and strategic vision:
                </p>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">
                        GPracta - A True Client Partner:
                      </span>{" "}
                      We consider our relationships with clients to be
                      partnerships where our consultants invest significant
                      amount of time and effort in understanding the clients
                      needs in detail both from a functional and technical
                      perspective.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">
                        Culturally Fit Candidates:
                      </span>{" "}
                      Our relationships with our customers is not only a
                      business relationship but we are trusted partner. Engaging
                      customers at the highest levels provides our consultants
                      with insight on their leadership and organizational
                      practices.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Full Transparency:</span>{" "}
                      Integrity, Commitment and trust are the bedrock of our
                      relationship with the client. These values foster open and
                      honest communication between clients, consultants and
                      candidates we present.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Consultative Approach:</span>{" "}
                      Our consultants work with clients closely constantly
                      collecting and giving feedback through out the entire
                      search cycle to ensure successful closures. of assignments
                      on time with highest quality.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Access to Expertise:</span> Gain
                      timely access to the right expertise, keeping your
                      organization ahead of industry trends and maintaining a
                      competitive edge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative bg-gradient-tint">
          <div className="container py-9 py-lg-11">
            <div className="row justify-content-center">
              {/* <!--Facts col--> */}
              <div className="col-lg-3 col-md-4 mb-5 mb-md-0 text-center">
                {/* <!--Facts card--> */}
                <div className="card border-0 bg-secondary">
                  {/* <!--fact-card-body--> */}
                  <div className="card-body p-4 position-relative text-white">
                    {/* <!--Facts digit--> */}
                    <h2
                      className="mb-4"
                      data-countup='{"startVal": "0","suffix":"+"}'
                      data-to="300"
                      data-aos
                      data-aos-id="countup:in"
                    >
                      0
                    </h2>
                    {/* <!--Facts title--> */}
                    <p className="mb-0">Happy Clients</p>
                  </div>
                </div>
              </div>
              {/* <!--Facts col--> */}
              <div className="col-lg-3 col-md-4 mb-5 mb-md-0 text-center">
                {/* <!--Facts card--> */}
                <div className="card border-0 bg-secondary">
                  {/* <!--fact-card-body--> */}
                  <div className="card-body p-4 position-relative text-white">
                    {/* <!--Facts digit--> */}
                    <h2
                      className="mb-4"
                      data-countup='{"startVal": "0","suffix":"+"}'
                      data-to="2165"
                      data-aos
                      data-aos-id="countup:in"
                    >
                      0
                    </h2>
                    {/* <!--Facts title--> */}
                    <p className="mb-0">Placements</p>
                  </div>
                </div>
              </div>
              {/* <!--Facts col--> */}
              <div className="col-lg-3 col-md-4 text-center">
                {/* <!--Facts card--> */}
                <div className="card border-0 bg-secondary">
                  {/* <!--fact-card-body--> */}
                  <div className="card-body p-4 position-relative text-white">
                    {/* <!--Facts digit--> */}
                    <h2
                      className="mb-4"
                      data-countup='{"startVal": "0","suffix":"+"}'
                      data-to="20"
                      data-aos
                      data-aos-id="countup:in"
                    >
                      0
                    </h2>
                    {/* <!--Facts title--> */}
                    <p className="mb-0">Years of Experience</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative">
          <div className="container pt-6 pb-7 pb-lg-9 position-relative">
            <div className="row pt-9 pt-lg-6  align-items-center">
              <h1>Our Executive Search Advantage</h1>
              <ul className="list-unstyled mb-0">
                <li>
                  <h5>Comprehensive Needs Assessment</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        We start by thoroughly understanding your organization's
                        leadership requirements, business goals, and cultural
                        dynamics. This allows us to define the ideal candidate
                        profile and ensure a perfect fit.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Extensive Talent Network</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        Leveraging our vast network of industry professionals,
                        we identify and reach out to both active and passive
                        candidates who meet your criteria. Our advanced search
                        tools and techniques enable us to find candidates who
                        may not be actively seeking new opportunities but are
                        ideally suited for your needs.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Rigorous Screening and Evaluation to ensure Quality</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        Our process involves a thorough screening and evaluation
                        of candidates to ensure they possess the necessary
                        expertise, leadership qualities, and cultural fit. We
                        conduct comprehensive interviews, background checks, and
                        assessments to present you with a shortlist of highly
                        qualified candidates. We adhere to a 3-to-1 policy: We
                        make 3 recommendations, and our clients select 1
                        candidate.
                      </span>
                    </li>
                  </ul>
                </li>

                <li>
                  <h5>Domain Expertise</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        Every year, we invest several hundred hours in training
                        our employees to develop domain expertise in various
                        subject matters.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Seamless Recruitment Process</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        We manage the entire recruitment process, from initial
                        contact to final offer negotiation. This ensures a
                        smooth and efficient experience for both clients and
                        candidates, with regular updates and transparent
                        communication throughout. We use enterprise grade
                        technology for recruitment process excellence and
                        analytics for generating quality outcomes continually.
                      </span>
                    </li>
                  </ul>
                </li>

                <li>
                  <h5>Onboarding Support</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        Once the ideal candidate is selected, we assist with the
                        onboarding process to ensure a smooth transition and
                        integration into your organization. Our support
                        continues beyond the hire, with follow-up consultations
                        to address any challenges and ensure long-term success.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Honesty, Trust, and Integrity</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        At GPracta, we prioritize honesty, trust, and integrity
                        in every transaction. We are committed to transparent
                        communication, ethical practices, and building long-term
                        relationships with our clients. Our dedication to these
                        core values ensures that every interaction is conducted
                        with the highest level of professionalism and respect,
                        fostering a trustworthy partnership.
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          {/* <!--Hero image--> */}
        </section>
        {/* <!--begin:Call to action section--> */}
        <section className="position-relative bg-body-tertiary border-top overflow-hidden">
          <div className="container py-9 py-lg-11 position-relative">
            <div className="row pb-7 position-relative">
              <div className="col-12 col-md-10 col-lg-12 mx-auto text-center">
                <h2 className="mb-4 display-5 fw-lighter" data-aos="fade-down">
                  Why Choose{" "}
                  <span className="fw-bolder d-inline-block pb-1 position-relative text-primary">
                    GPracta
                  </span>
                  ?
                </h2>
                <p
                  className="mb-5 px-lg-7 lead"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  GPracta's Executive Search service is your strategic partner
                  in securing top-tier leadership talent. Our commitment to
                  excellence, combined with our personalized approach and
                  industry expertise, ensures that you find leaders who can
                  drive your organization forward. Partner with GPracta to
                  elevate your leadership team and achieve your strategic goals.
                  Contact us today to learn more about how we can help with your
                  executive search and recruiting needs.
                </p>
                <p
                  className="mb-5 px-lg-7 lead"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  Contact us today to learn more about how we can help you
                  achieve your recruitement goals.
                </p>
                <div data-aos="fade-up" data-aos-delay="150">
                  <a
                    href="/contact-us"
                    className="btn btn-outline-primary btn-hover-arrow btn-lg"
                  >
                    <span>Click to Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ExecutiveSearch;
