import React from "react";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page Not Found</title>
        <link rel="canonical" href="/blogs" />
      </Helmet>
      <div
        className="card text-center"
        style={{ width: "50%", marginLeft: "25%", marginTop: "8.5%" }}
      >
        <div className="card-header">404</div>
        <div className="card-body">
          <h5 className="card-title">Page Not Found</h5>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
