import React, { useState, useEffect } from "react";
import searchService from "./SearchQuery";
import axios from "axios";

const baseURL = process.env.react_app_opensearch_url;
const username = process.env.react_app_opensearch_username;
const password = process.env.react_app_opensearch_password;

const ManageGPractaBlogs = () => {
  const [results, setResults] = useState([]);
  const [, setError] = useState("");
  const [, setQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await searchService.getAllData();
        setResults(data);
      } catch (error) {
        console.error("Error fetching all data:", error);
        setError("Failed to fetch data. Please try again later.");
        setResults([]); // Optionally clear results on error
      }
    };

    fetchData();
  }, []);

  const handleSearch = async (query) => {
    setQuery(query); // Store query in state
    if (!query.trim()) {
      // If the query is empty, consider reloading all data or leaving current all data view
      return;
    }
    try {
      const data = await searchService.search(query);
      setResults(data);
    } catch (error) {
      console.error("Error searching:", error);
      setError("Search failed. Please try again later.");
      setResults([]); // Clear results on error
    }
  };

  // const handleSearch = async (query) => {
  //   try {
  //     const data = await searchNewsService.search(query);
  //     setResults(data.hits.hits);
  //   } catch (error) {
  //     console.error("Error searching:", error);
  //   }
  // };
  const currentDate = new Date();
  const years = currentDate.getFullYear();
  const months = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because January is 0
  const days = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${years}-${months}-${days}`;

  const dateString = "2024-04-24";
  // const [years, months, days] = dateString.split('-');
  // const monthName = new Date(dateString.split("-")).toLocaleString("en-US", {
  //   month: "long",
  // });

  const [day, month, year] = dateString.split("/");

  const handleSubmit = async (id) => {
    // e.preventDefault();
    try {
      const response = await axios.delete(
        `${baseURL}/blog/_doc/${id}`,

        {
          auth: {
            username: username,
            password: password,
          },
        }
      );
      window.location = "/admin/manage-blogs";
      console.log(response);
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  return (
    <>
      {/* <div className="card">
        <div className="card-body">
          {" "}
          <h1 style={{ textAlign: "center" }}> News and Events</h1>
        </div>
      </div> */}
      {results.map((result) => (
        <div key={result._id}>
          <div
            className="modal fade"
            id={`exampleModal_${result._id}`}
            tabIndex="-1"
            aria-labelledby={`exampleModalLabel_${result._id}`}
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1
                    className="modal-title fs-5"
                    id={`exampleModalLabel_${result._id}`}
                  >
                    Delete Item ?
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to delete this item ?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      handleSubmit(result._id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="container-lg">
        <div className="insights">
          <button
            type="button"
            className="btn btn-success"
            style={{ float: "right", margin: 10 }}
            onClick={() => {
              window.location = "/add-gpracta-blog";
            }}
          >
            <a
              href="/add-gpracta-blog"
              style={{ textDecoration: "none", color: "#fafcfe" }}
            >
              + Add Blogs
            </a>
          </button>
          <form
            className="d-flex"
            role="search"
            style={{ float: "right", margin: 10 }}
          >
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search Blog"
              aria-label="Search"
              style={{ width: "100%" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {/* <button className="btn btn-outline-success" type="submit">
              Search
            </button> */}
          </form>
          <br />
          <h4 style={{ textAlign: "center" }}>GPracta Blogs </h4>
          <br />{" "}
          {/* <div className="news-card">
          <a href="ss">
            <img className="thumbnail"/>
            <h4>Title</h4>
            <p>Date</p>
          </a>
        </div> */}{" "}
          <div className="row row-cols-1 row-cols-md-3 g-4">
            {results.map((result) => (
              <div className="col" key={result._id}>
                <div className="card h-100">
                  {/* <img src="..." className="card-img-top" alt="..."> */}
                  <div className="card-body">
                    <h5 className="card-title">{result._source.source}</h5>
                    <a
                      href={result._source.blog_link}
                      className="card-text"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      {result._source.content}
                      {/* {console.log(result._source)} */}
                    </a>
                  </div>
                  <div className="card-footer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-clock-history"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                    </svg>{" "}
                    <small className="text-body-secondary">
                      {new Date(`${year}-${month}-${day}`).toLocaleDateString(
                        "en-US",
                        { month: "long", day: "numeric", year: "numeric" }
                      )}
                    </small>
                    <span title="Delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash3"
                        viewBox="0 0 16 16"
                        style={{
                          float: "right",
                          margin: 10,
                          cursor: "pointer",
                          color: "red",
                        }}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target={`#exampleModal_${result._id}`} // Dynamically target modal for each item
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                    </span>
                    <a href={`/edit-news/${result._id}`} title="Edit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                        style={{
                          float: "right",
                          margin: 10,
                          cursor: "pointer",
                          color: "green",
                        }}
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                        />
                      </svg>
                    </a>
                    <small className="text-body-secondary">
                      {result._source.expiry_date === formattedDate ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-calendar-x"
                          viewBox="0 0 16 16"
                          title="expired"
                          style={{
                            float: "right",
                            margin: 10,
                            cursor: "pointer",
                            color: "grey",
                          }}
                        >
                          <path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708" />
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                        </svg>
                      ) : (
                        <></>
                      )}
                    </small>
                    <span title="Expired"> </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageGPractaBlogs;
