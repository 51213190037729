import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

const baseURL = process.env.react_app_opensearch_url;
const username = process.env.react_app_opensearch_username;
const password = process.env.react_app_opensearch_password;

const AddBlog = () => {
  const [formData, setFormData] = useState({
    source: "",
    title: "",
    blog_link: "",
    posted_date: `${new Date().toLocaleDateString("en-GB")}`,
    expiry_date: "",
    category: "",
    content_excerpt: "",
    logo: "",
    author: "",
    author_logo: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/news/_doc`,

        formData,
        {
          auth: {
            username: username,
            password: password,
          },
        }
      );
      console.log("Document added:", response.data);
      // Reset form after successful submission
      setFormData({
        source: "",
        title: "",
        blog_link: "",
        posted_date: new Date(),
        expiry_date: "",
        category: "",
        content_excerpt: "",
        logo: "",
        author: "",
        author_logo: "",
      });

      window.location = "/blogs";
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Blog</title>
        <link rel="canonical" href="/blogs" />
      </Helmet>
      <div className="container-lg">
        <br />
        <h4 style={{ textAlign: "center" }}>Add News </h4>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Source{" "}
            </label>
            <input
              type="text"
              name="source"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Source"
              value={formData.source}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Title"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Category{" "}
            </label>
            <input
              type="text"
              name="category"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Category"
              value={formData.category}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Content Excerpt{" "}
            </label>
            <input
              type="text"
              name="content_excerpt"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Content Excerpt"
              value={formData.content_excerpt}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Author
            </label>
            <input
              type="text"
              name="author"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Author"
              value={formData.author}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Link
            </label>
            <input
              type="text"
              name="blog_link"
              value={formData.blog_link}
              placeholder="Link"
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Upload Date{" "}
            </label>
            <input
              type="text"
              name="posted_date"
              value={formData.posted_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Upload Date"
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Expiry Date{" "}
            </label>
            <input
              type="date"
              name="expiry_date"
              value={formData.expiry_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Expiry Date"
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label for="formFile" className="form-label">
              Logo
            </label>
            <input
              type="file"
              name="logo"
              value={formData.logo}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Logo"
              onChange={handleChange}
              required
            />{" "}
          </div>
          <div className="mb-3">
            <label for="formFile" className="form-label">
              Author Logo
            </label>
            <input
              type="file"
              name="author_logo"
              value={formData.author_logo}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Author Logo"
              onChange={handleChange}
              required
            />{" "}
          </div>
          <button
            type="submit"
            className="btn btn-success"
            style={{ float: "right" }}
          >
            Save Blog
          </button>
        </form>
        <br />
        <br />

        <br />
        <br />
      </div>
    </>
  );
};

export default AddBlog;
