import React, { useState } from "react";
import axios from "axios";

const baseURL = process.env.react_app_opensearch_url;
const username = process.env.react_app_opensearch_username;
const password = process.env.react_app_opensearch_password;

const AddDataComponent = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    title: "",
    blog_link: "",
    posted_date: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/blog/_doc`,

        formData,
        {
          auth: {
            username: username,
            password: password,
          },
        }
      );
      console.log("Document added:", response.data);
      // Reset form after successful submission
      setFormData({
        company_name: "",
        title: "",
        blog_link: "",
        posted_date: "",
      });
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  return (
    <div>
      <h2>Add Data to OpenSearch</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Customer Full Name:</label>
          <input
            type="text"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>title:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>blog_link:</label>
          <input
            type="text"
            name="blog_link"
            value={formData.blog_link}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>posted_date:</label>
          <input
            type="text"
            name="posted_date"
            value={formData.posted_date}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Add Data</button>
      </form>
    </div>
  );
};

export default AddDataComponent;
