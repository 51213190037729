import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const baseURL = process.env.react_app_opensearch_url;
const username = process.env.react_app_opensearch_username;
const password = process.env.react_app_opensearch_password;

const EditBlog = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    title: "",
    blog_link: "",
    posted_date: "",
  });
  let { id } = useParams();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/blog/_doc/${id}`,

        formData,
        {
          auth: {
            username: username,
            password: password,
          },
        }
      );
      console.log("Document added:", response.data);
      // Reset form after successful submission
      setFormData({
        company_name: "",
        title: "",
        blog_link: "",
        posted_date: "",
      });

      window.location = "/blogs";
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Blog</title>
      </Helmet>
      <div className="container-lg">
        {/* <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>

        <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div> */}
        <br />
        <h4 style={{ textAlign: "center" }}>Edit Blog </h4>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Source{" "}
            </label>
            <input
              type="text"
              name="company_name"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Source"
              value={formData.company_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Article Link"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Link
            </label>
            <input
              type="text"
              name="blog_link"
              value={formData.blog_link}
              placeholder="Blog Link"
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Upload Date{" "}
            </label>
            <input
              type="date"
              name="posted_date"
              value={formData.posted_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Upload Date"
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Expiry Date{" "}
            </label>
            <input
              type="date"
              name="posted_date"
              value={formData.posted_date}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Upload Date"
              onChange={handleChange}
              required
            />
          </div> */}

          {/* <div className="mb-3">
            <label for="formFile" className="form-label">
              Thumbnail
            </label>
            <input className="form-control" type="file" id="formFile" />
          </div> */}
          <button
            type="submit"
            className="btn btn-success"
            style={{ float: "right" }}
          >
            Update Blog
          </button>
        </form>
      </div>
    </>
  );
};

export default EditBlog;
