import React from "react";
import { Helmet } from "react-helmet";

export const GlobalCapabilityCenter = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Global Capability Centers</title>
        <link rel="canonical" href="/global-capability-centers" />
      </Helmet>

      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>

      <main>
        <section className="position-relative bg-gradient-tint">
          <div className="container position-relative pt-14 pb-9">
            <div className="row pt-lg-9 pb-lg-4">
              <div className="col-lg-10 mx-auto text-center">
                <h1 className="display-4 mb-3">Global Capability Centers</h1>
                <p className="lead mb-0">
                  Empower your business with seamless and efficient global
                  capability centers through GPracta.
                </p>
              </div>
            </div>
            {/* <!--/.row--> */}
          </div>
          {/* <!--/.content--> */}
        </section>
        <section className="position-relative">
          <div className="container pt-6 pb-7 pb-lg-9 position-relative">
            <div className="row pt-9 pt-lg-6  align-items-center">
              <p>
                Global Capability Centers (GCC) are units of global companies
                located in India that foster technology innovation and enhance
                capabilities across all organizational functions. Teams within
                GCCs boost productivity in business process management, improve
                cost efficiencies across the supply chain, and enrich the
                customer experience at every touchpoint.
              </p>
            </div>
          </div>
          {/* <!--Hero image--> */}
        </section>
        {/* <!--begin:feature image section--> */}
        <section className="position-relative">
          <div className="container position-relative py-9 py-lg-8">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-lg-6 mb-5 mb-lg-0 order-lg-last"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="position-relative p-2">
                  {/* <!--background-parallax-shape--> */}
                  <div
                    className="rellax bg-warning width-9x height-9x rounded-circle position-absolute end-0 top-0"
                    data-rellax-percentage="0.95"
                    data-rellax-speed="2"
                  ></div>
                  {/* <!--background-parallax-shape--> */}
                  <div
                    className="rellax bg-danger width-5x height-5x rounded-circle position-absolute bottom-0 mb-5 start-0"
                    data-rellax-percentage="0.1"
                    data-rellax-speed="-1"
                  ></div>

                  {/* <!--Shape Image with mask--> */}
                  <div className="bg-mask">
                    <img
                      src="assets/img/960x900/gcc-team.png"
                      className="mask-squircle mask-image"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 order-lg-1 position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {/* <!--Heading--> */}
                <h2 className="mb-4 display-6">
                  Why consider{" "}
                  <span className="text-gradient">
                    Global Capability Centers
                  </span>
                  ?
                </h2>

                {/* <!--Text--> */}
                <p className="mb-5">
                  Consider GCCs for their ability to drive technology innovation
                  and operational excellence. They enhance productivity,
                  optimize costs, and provide a superior customer experience,
                  positioning your organization for sustained growth and
                  competitive advantage.
                </p>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Financial:</span> Investing in
                      GCCs tangibly expands profit margins by reducing the Total
                      Cost of Ownership (TCO) for products or services.
                      Additionally, a 24/7 model becomes feasible, complementing
                      capacities at the parent location. These efficiencies
                      improve business process metrics across the board.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">
                        Business Continuity Planning/Disaster Recovery:
                      </span>{" "}
                      Emergency turnaround is possible when provisions are made
                      within Global Capability Centers.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Human Capital:</span> India
                      offers a large pool of English-speaking professionals,
                      including engineers, HR specialists, sales personnel, and
                      customer support representatives.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Customer Success:</span>{" "}
                      Proactive support enhances customer satisfaction. GCCs
                      enable parent organizations to improve turnaround time
                      (TAT) by providing additional touchpoints and
                      communication channels for serving customers.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Technology:</span> Global
                      companies have leveraged GCCs in India to develop IT
                      products and services for the world. India offers a rich
                      talent pool of English-speaking engineers with software
                      development skills across the technology landscape.
                    </p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">
                        Business Process Management:
                      </span>{" "}
                      Global companies have utilized GCCs to augment capacities
                      in non-core areas such as Finance (AP, AR), Marketing
                      (Automation, Digital Marketing, Content Management), and
                      Customer Service (Chat, Phone), thereby enhancing business
                      process efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative bg-body-tertiary">
          <div className="container py-9">
            <div className="row align-items-center">
              <div className="col-md-10 col-lg-8 mx-auto text-center">
                <div>
                  <h1 className="mb-0 display-5">Our Service Models for GCC</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          {/* <!--Price Container--> */}
          <div className="container">
            {/* <!--Price Row--> */}
            <div className="row grid-separator">
              {/* <!--Price col--> */}
              <div className="col-lg-4 py-9 py-lg-11 px-4 px-xl-5">
                {/* <!--Price card--> */}
                <div className="card bg-transparent border-0 h-100">
                  {/* <!--Badge--> */}
                  <div className="mb-3">
                    <span className="badge badge-pill bg-success px-3">
                      Quick Setup
                    </span>
                  </div>
                  {/* <!--Title--> */}
                  <h1 className="mb-4 text-gradient">Hosted</h1>

                  {/* <!--Features--> */}
                  <h6 className="mb-3">Ideal Fit</h6>
                  <p className="mb-4">
                    For Micro and Small enterprises looking for team sizes in
                    the range of 1-100.
                  </p>
                  <h6 className="mb-3">Setup Services</h6>
                  <p className="mb-4">
                    Not required as the teams are incubated by the host.
                  </p>
                  <h6 className="mb-3">Transition Services</h6>
                  <p className="mb-4">
                    Roadmap to be defined to identify FTEs to be brought on
                    board.
                  </p>
                  <h6 className="mb-3">Pricing</h6>
                  <p className="mb-4">
                    Easy “Pay as you go ” and transparent pricing for our
                    services.
                  </p>
                  <h6 className="mb-3">Service Bundle</h6>
                  <p className="mb-4">
                    Talent Acquisition, HR Services, Fully functional Managed
                    Office Space, Laptop/Desktops/Network Infra, GRC(Local,
                    State, Country).
                  </p>
                </div>
              </div>
              {/* <!--Price col--> */}
              <div className="col-lg-4 py-9 py-lg-11 px-4 px-xl-5">
                {/* <!--Price card--> */}
                <div className="card bg-transparent border-0 h-100">
                  {/* <!--Title--> */}
                  <h1 className="mb-4 text-gradient">
                    Build, Operate & Transfer (BOT)
                  </h1>

                  {/* <!--Features--> */}
                  <h6 className="mb-3">Ideal Fit</h6>
                  <p className="mb-4">
                    For SMEs for team sizes in the range of 100-500 over a span
                    of 1-2 years.
                  </p>
                  <h6 className="mb-3">Setup and Transition Services</h6>
                  <p className="mb-4">
                    Plan required as the team grows. Hosted model capabilities
                    can be used to start services for first 25 heads. Subsequent
                    headcount addition required roadmap and planning.
                  </p>
                  <h6 className="mb-3">Pricing</h6>
                  <p className="mb-4">
                    Depends on the number of FTEs and overhead costs incurred
                    during a particular period.
                  </p>
                  <h6 className="mb-3">Service Bundle</h6>
                  <p className="mb-4">
                    Includes all of Hosted model. In addition dedicated customer
                    representatives to be onboarded to build and operate
                    customer teams.
                  </p>
                </div>
              </div>

              {/* <!--Price col--> */}
              <div className="col-lg-4 py-9 py-lg-11 px-4 px-xl-5">
                {/* <!--Price card--> */}
                <div className="card bg-transparent border-0 h-100">
                  {/* <!--Title--> */}
                  <h1 className="mb-4 text-gradient">Captive</h1>

                  {/* <!--Features--> */}
                  <h6 className="mb-3">Ideal Fit</h6>
                  <p className="mb-4">
                    For larger corporations who plan to have dedicated centers
                    for large teams 500+ FTEs.
                  </p>
                  <h6 className="mb-3">Setup Services</h6>
                  <p className="mb-4">
                    Company incorporation, Location assessments, Transfer
                    pricing, Book Keeping, Tax Structures.
                  </p>
                  <h6 className="mb-3">Transition Services and Roadmap</h6>
                  <p className="mb-4">
                    Build and operate staffing until center is up and running.
                  </p>
                  <h6 className="mb-3">Pricing</h6>
                  <p className="mb-4">
                    Depends on the number of FTEs and overhead costs incurred
                    during a particular period.
                  </p>
                  <h6 className="mb-3">Service Bundle</h6>
                  <p className="mb-4">
                    Includes all of setup services, transition services for
                    organization planning, structure and staffing.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!--End price container--> */}
        </section>

        {/* <!--begin:Call to action section--> */}
        <section className="position-relative bg-body-tertiary border-top overflow-hidden">
          <div className="container py-9 py-lg-11 position-relative">
            <div className="row pb-7 position-relative">
              <div className="col-12 col-md-10 col-lg-12 mx-auto text-center">
                <h2 className="mb-4 display-5 fw-lighter" data-aos="fade-down">
                  Why Choose{" "}
                  <span className="fw-bolder d-inline-block pb-1 position-relative text-primary">
                    GPracta
                  </span>{" "}
                  for your Global Capability Center in India?
                </h2>
                <p
                  className="mb-5 px-lg-7 lead"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  Unlock the full potential of your business by establishing a
                  Global Capability Center (GCC) in India with GPracta. GCCs are
                  essential for leveraging India's vast talent pool, driving
                  innovation, and achieving operational excellence. Whether you
                  are a small or medium enterprise (SME) or a large company,
                  GPracta provides tailored solutions to meet your unique needs.
                  Our expertise in setting up and managing GCCs ensures seamless
                  integration, cost efficiency, and enhanced productivity.
                </p>
                <p
                  className="mb-5 px-lg-7 lead"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  Partner with GPracta to gain a competitive edge, optimize your
                  processes, and deliver exceptional value to your customers.
                  Contact us today to learn how we can help you succeed in
                  India.
                </p>
                <div data-aos="fade-up" data-aos-delay="150">
                  <a
                    href="/contact-us"
                    className="btn btn-outline-primary btn-hover-arrow btn-lg"
                  >
                    <span>Click to Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
