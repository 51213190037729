import React from "react";
import ManageBlogs from "./manage-blogs";
import ManageNewsletter from "./manage-newsletter";
import ManageGPractaBlogs from "./manage-gpracta-blogs";
import { Helmet } from "react-helmet";
// import 'bootstrap/dist/css/bootstrap.min.css';


const ManageAllBlogs = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Required meta tags --> */}
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="assets/img/favicon.ico" type="image/ico" />

        {/* <!--Box Icons--> */}
        <link
          rel="stylesheet"
          href="assets/fonts/boxicons/css/boxicons.min.css"
        />

        {/* <!--Iconsmind Icons--> */}
        <link rel="stylesheet" href="assets/fonts/iconsmind/iconsmind.css" />
        {/* <!--Choices select--> */}
        <link
          rel="stylesheet"
          href="assets/vendor/node_modules/css/choices.min.css"
        />

        {/* <!--Swiper--> */}
        <link
          rel="stylesheet"
          href="assets/vendor/node_modules/css/swiper-bundle.min.css"
        />
        {/* <!-- Aos Animations CSS --> */}
        <link href="assets/vendor/node_modules/css/aos.css" rel="stylesheet" />

        {/* <!-- Google fonts CSS --> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=PT+Serif:ital@0;1&display=swap"
          rel="stylesheet"
        />
        {/* <!-- Main CSS --> */}
        <link href="assets/css/theme-green.min.css" rel="stylesheet" />

        <title>
          GPracta - Scale your team with a Global Capability Center in India
        </title>
      </Helmet>
      <ul className="nav nav-tabs" id="myTab" role="tablist" style={{marginTop:100}}>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home-tab-pane"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            News
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile-tab-pane"
            type="button"
            role="tab"
            aria-controls="profile-tab-pane"
            aria-selected="false"
          >
            Blogs
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact-tab-pane"
            type="button"
            role="tab"
            aria-controls="contact-tab-pane"
            aria-selected="false"
            disabled
            style={{ cursor: "not-allowed" }}
          >
            Newsletters
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex="0"
        >
          <ManageBlogs />
        </div>
        <div
          className="tab-pane fade"
          id="profile-tab-pane"
          role="tabpanel"
          aria-labelledby="profile-tab"
          tabIndex="0"
        >
          <ManageGPractaBlogs />
        </div>
        <div
          className="tab-pane fade"
          id="contact-tab-pane"
          role="tabpanel"
          aria-labelledby="contact-tab"
          tabIndex="0"
        >
          <ManageNewsletter />{" "}
        </div>
      </div>
    </>
  );
};

export default ManageAllBlogs;
