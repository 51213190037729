import React from "react";
import { Helmet } from "react-helmet";

const CenterOfExcellence = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Center of Excellence</title>
        <link rel="canonical" href="/center-of-excellence" />
      </Helmet>

      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>

      <main>
        <section className="position-relative bg-gradient-tint">
          <div className="container position-relative pt-14 pb-9">
            <div className="row pt-lg-9 pb-lg-4">
              <div className="col-lg-10 mx-auto text-center">
                <h1 className="display-4 mb-3">Center of Excellence</h1>
                <p className="lead mb-0">
                  Leverage GPracta's expertise to establish Centers of
                  Excellence in India, empowering your organization with
                  cutting-edge knowledge and competency across business units
                  and product lines.
                </p>
              </div>
            </div>
            {/* <!--/.row--> */}
          </div>
          {/* <!--/.content--> */}
        </section>
        <section className="position-relative">
          <div className="container pt-6 pb-7 pb-lg-9 position-relative">
            <div className="row pt-9 pt-lg-6  align-items-center">
              <h1>What is a Center of Excellence (CoE)?</h1>
              <p>
                A Center of Excellence (CoE) is a dedicated body within an
                organization that works across business units (BUs) or product
                lines within a BU, embodying leading-edge knowledge and
                competency in a specific area. CoEs drive innovation, optimize
                processes, and enhance performance by leveraging specialized
                expertise and best practices.
              </p>
            </div>
          </div>
          {/* <!--Hero image--> */}
        </section>
        {/* <!--begin:feature image section--> */}
        <section className="position-relative">
          <div className="container position-relative py-9 py-lg-8">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-lg-6 mb-5 mb-lg-0 order-lg-last"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="position-relative p-2">
                  {/* <!--background-parallax-shape--> */}
                  <div
                    className="rellax bg-warning width-9x height-9x rounded-circle position-absolute end-0 top-0"
                    data-rellax-percentage="0.95"
                    data-rellax-speed="2"
                  ></div>
                  {/* <!--background-parallax-shape--> */}
                  <div
                    className="rellax bg-danger width-5x height-5x rounded-circle position-absolute bottom-0 mb-5 start-0"
                    data-rellax-percentage="0.1"
                    data-rellax-speed="-1"
                  ></div>

                  {/* <!--Shape Image with mask--> */}
                  <div className="bg-mask">
                    <img
                      src="assets/img/960x900/coe-team-3.png"
                      className="mask-squircle mask-image"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 order-lg-1 position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {/* <!--Heading--> */}
                <h2 className="mb-4 display-6">
                  Benefits of a{" "}
                  <span className="text-gradient">Center of Excellence </span>
                </h2>

                {/* <!--Text--> */}
                <p className="mb-5">
                  Establishing a Center of Excellence (CoE) with GPracta offers
                  a multitude of benefits for your organization:
                </p>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">
                        Efficient Resource Utilization:
                      </span>{" "}
                      Centralize expertise and streamline processes to maximize
                      the efficiency of your resources.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Rapid Results Delivery:</span>{" "}
                      Enable your team to deliver outcomes quickly and
                      effectively.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Cost Optimization:</span>{" "}
                      Achieve more with less by optimizing operational costs.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Enhanced Quality:</span> Improve
                      the overall quality of your services and products through
                      rigorous standards and continuous improvement practices.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Access to Expertise:</span> Gain
                      timely access to the right expertise, keeping your
                      organization ahead of industry trends and maintaining a
                      competitive edge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative overflow-hidden bg-gradient-primary text-white">
          <div className="container py-9 py-lg-11 position-relative z-1">
            <div className="row mb-7">
              <div className="col-lg-8 mx-auto text-center">
                <div className="mb-3" data-aos="fade-up">
                  <h6 className="mb-0">
                    Designed to fulfill missions that drive organizational
                    success and innovation.
                  </h6>
                </div>
                <div className="mb-3" data-aos="fade-up">
                  <h1 className="mb-0 display-5">
                    Core Missions of a Center of Excellence
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-5 mb-lg-0" data-aos="fade-up">
                <div className="position-relative">
                  <span className="d-block position-absolute start-50 top-0 translate-middle-x lh-1 display-1 opacity-25">
                    01
                  </span>
                  <div className="position-relative pt-4 pt-lg-6">
                    <h5 className="mb-4">Operational Excellence</h5>
                    <p className="mb-0 opacity-75 w-lg-75">
                      Achieve superior efficiency by reducing administrative
                      costs, decreasing turnaround time for customer responses,
                      and expanding capacities in cost-effective locations. Ex:
                      AP, AR, IT Service Desks, etc.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 mb-5 mb-lg-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="position-relative">
                  <span className="d-block position-absolute start-50 top-0 translate-middle-x lh-1 display-1 opacity-25">
                    02
                  </span>
                  <div className="position-relative pt-4 pt-lg-6">
                    <h5 className="mb-4">Organizational Growth</h5>
                    <p className="mb-0 opacity-75 w-lg-75">
                      Enhance productivity to boost organizational
                      competitiveness and optimize costs. Ex: Product
                      Development, Internal Consulting
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 mb-5 mb-lg-0"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="position-relative">
                  <span className="d-block position-absolute start-50 top-0 translate-middle-x lh-1 display-1 opacity-25">
                    03
                  </span>
                  <div className="position-relative pt-4 pt-lg-6">
                    <h5 className="mb-4">Enterprise Transformation</h5>
                    <p className="mb-0 opacity-75 w-lg-75">
                      Contribute strategically and drive innovation to uncover
                      new capabilities, markets, products, and business models.
                      Ex: R&D Teams
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative">
          <div className="container pt-6 pb-7 pb-lg-9 position-relative">
            <div className="row pt-9 pt-lg-6  align-items-center">
              <h4>How GPracta Can Help You Set Up Your CoE</h4>
              <p>
                At GPracta, we specialize in helping businesses establish
                Centers of Excellence in India, tailored to their unique needs
                and goals. Our comprehensive approach ensures that your CoE
                becomes a hub of innovation and excellence, driving your
                organization forward. Here's how we can assist you:
              </p>
              <ul className="list-unstyled mb-0">
                <li>
                  <h5>Strategic Planning and Assessment</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        We begin by understanding your business objectives,
                        challenges, and the specific areas where a CoE can add
                        the most value.
                      </span>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        Our experts conduct a thorough assessment to identify
                        opportunities for improvement and innovation within your
                        existing operations.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Customized CoE Design</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        Based on our assessment, we design a CoE structure that
                        aligns with your strategic goals and organizational
                        culture.
                      </span>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        We ensure that the CoE integrates seamlessly with your
                        existing business units, fostering collaboration and
                        knowledge sharing.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Talent Acquisition and Development</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        GPracta assists in sourcing top talent with the
                        specialized skills and expertise required for your CoE.
                      </span>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        We provide training and development programs to ensure
                        your team stays at the forefront of industry trends and
                        best practices.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Implementation and Integration</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        Our team manages the entire setup process, from
                        infrastructure development to technology integration,
                        ensuring a smooth and efficient launch.
                      </span>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        We work closely with your internal teams to integrate
                        the CoE into your broader organizational framework,
                        promoting synergy and collaboration.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Ongoing Support and Optimization</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        GPracta offers continuous support to help you manage and
                        optimize your CoE operations.
                      </span>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <i className="bx bx-check-circle fs-4 opacity-50 me-2"></i>
                      <span>
                        We provide regular performance evaluations and
                        improvement strategies to ensure your CoE remains a
                        driving force for innovation and excellence.
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          {/* <!--Hero image--> */}
        </section>
        {/* <!--begin:Call to action section--> */}
        <section className="position-relative bg-body-tertiary border-top overflow-hidden">
          <div className="container py-9 py-lg-11 position-relative">
            <div className="row pb-7 position-relative">
              <div className="col-12 col-md-10 col-lg-12 mx-auto text-center">
                <h2 className="mb-4 display-5 fw-lighter" data-aos="fade-down">
                  Why Choose{" "}
                  <span className="fw-bolder d-inline-block pb-1 position-relative text-primary">
                    GPracta
                  </span>
                  ?
                </h2>
                <p
                  className="mb-5 px-lg-7 lead"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  With GPracta's proven expertise and comprehensive approach,
                  establishing a Center of Excellence in India becomes a
                  strategic advantage for your business. Our commitment to
                  excellence and personalized service ensures that your CoE not
                  only meets but exceeds your expectations, delivering sustained
                  value and competitive edge.
                </p>
                <p
                  className="mb-5 px-lg-7 lead"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  Partner with GPracta to unlock the full potential of your
                  organization through a world-className Center of Excellence.
                  Contact us today to learn more about how we can help you
                  achieve your goals.
                </p>
                <div data-aos="fade-up" data-aos-delay="150">
                  <a
                    href="/contact-us"
                    className="btn btn-outline-primary btn-hover-arrow btn-lg"
                  >
                    <span>Click to Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default CenterOfExcellence;
