import React from "react";
import { Helmet } from "react-helmet";

const Admin = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin</title>
        <link rel="canonical" href="/blogs" />
      </Helmet>

      <div className="container-lg" style={{marginTop:100}}>
        <br />

        <div className="card text-center">
          <div className="card-body">
            <h5 className="card-title">
              Welcome to GPracta Technology Services
            </h5>

            {/* <button type="button" className="btn btn-secondary" style={{borderRadius:20}}> */}
              <a
                href="/manage-all-blogs"
                style={{ textDecoration: "none", color: "#fafcfe" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                  style={{color:"red"}}
                  title="Manage Content"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </a>
            {/* </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
