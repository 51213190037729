import axios from "axios";
const baseURL = process.env.react_app_opensearch_url;
const username = process.env.react_app_opensearch_username;
const password = process.env.react_app_opensearch_password;

const searchNewsService = {
  getAllData: async () => {
    try {
      const response = await axios.post(
        `${baseURL}/news/_search`,
        {
          query: {
            match_all: {},
          },

          size: 20,
          // "posted_date":"desc"
        },
        {
          auth: {
            username,
            password,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.hits.hits;
    } catch (error) {
      console.error("Failed to fetch all data:", error);
      throw error;
    }
  },
  search: async (query) => {
    try {
      const response = await axios.get(`${baseURL}/news/_search?q=${query}`, {
        auth: {
          username,
          password,
        },
      });
      return response.data.hits.hits;
    } catch (error) {
      console.error("Search failed:", error);
      throw error;
    }
  },
};

export default searchNewsService;
