import React from "react";
import "./home.css";

const Home = () => {
  return (
    <>
      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>

      <main>
        {/* <!--begin:Feature image section--> */}
        <section className="position-relative overflow-hidden bg-body-secondary">
          <div className="container position-relative z-1 py-9 py-lg-11">
            <div className="row align-items-center">
              <div className="col-lg-6 ms-lg-auto mb-5 mt-lg-5 mb-lg-0 order-lg-last">
                <div className="bg-mask">
                  <img
                    src="assets/img/960x900/home-image.jpg"
                    alt=""
                    className="mask-image mask-squircle"
                  />
                </div>
              </div>

              <div className="col-lg-5 order-lg-1">
                {/* <!--begin:swiper text-slider--> */}
                <div className="swiper-container swiper-text w-100 height-3x fs-5 fw-bold text-uppercase mb-3 text-white overflow-hidden">
                  {/* <!--begin:Slider wrapper--> */}
                  <div className="swiper-wrapper text-warning">
                    {/* <!--Slide item--> */}
                    <div className="swiper-slide justify-content-center">
                      Your Partner in Workforce Innovation
                    </div>
                  </div>
                  {/* <!--end:Slider wrapper--> */}
                </div>
                {/* <!--end:swiper text-slider--> */}
                <h1 className="display-5 mb-4 mb-lg-5">
                  Managed{" "}
                  <span className="text-gradient">
                    Global Capability Centers
                  </span>{" "}
                  for SMEs
                </h1>
                <p
                  className="position-relative mb-7"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Unlock global potential with GPracta's expertise in setting up
                  GCCs in India. Enhance your capabilities across software
                  development, R&D, customer success, digital marketing,
                  operations support, and more.
                </p>
                {/* <!--Hero action button--> */}
                <div className="d-flex align-items-left justify-content-left">
                  <a
                    href="/contact-us"
                    className="btn btn-primary btn-lg me-2 me-lg-3"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--/end:Feature image section--> */}
        <section className="position-relative overflow-hidden">
          <div className="container position-relative z-1 py-9 py-lg-11">
            <div className="row">
              {/* <!--Feature column--> */}
              <div
                className="col-12 col-lg mb-7 mb-lg-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="position-relative">
                  <div className="position-relative">
                    <h2 className="mb-4 display-6">
                      Your Partner in Building{" "}
                      <span className="text-gradient">
                        Global Capability Centers
                      </span>
                    </h2>
                    <p>
                      Established in 2004, GPracta is a leading managed services
                      provider specializing in helping organizations set up and
                      manage Global Capability Centers (GCC) in India. Our
                      mission is to enable companies to leverage India's rich
                      talent pool, optimize costs, and foster innovation to make
                      their organizations future-ready. With trust and integrity
                      as our cornerstones, we have successfully deployed teams
                      across various organizational functions including Sales,
                      Operations, Marketing, Technology, and HR.
                    </p>
                    <h4>Who We Serve</h4>
                    <p>
                      Our services are tailor-made for organizations of all
                      sizes, from startups and small and medium-sized
                      enterprises (SMEs) to large corporations. We offer
                      scalable solutions that allow you to start small and grow
                      as needed, ensuring you get the maximum benefit from your
                      investment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--begin: Testimonial section--> */}
        <section className="position-relative bg-primary text-white overflow-hidden">
          {/* <!--begin: Divider shape--> */}
          <svg
            className="position-absolute start-0 top-0"
            style={{ color: "var(--bs-body-bg)" }}
            width="100%"
            height="48"
            preserveAspectRatio="none"
            viewBox="0 0 1284 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0L31.03 14.5833C60.99 29.1667 121.98 58.3333 182.97 62.5C245.03 66.6667 306.02 45.8333 367.01 35.4167C428 25 488.99 25 549.98 37.5C610.97 50 673.03 75 734.02 70.8333C795.01 66.6667 856 33.3333 916.99 31.25C977.98 29.1667 1038.97 58.3333 1101.03 75C1162.02 91.6667 1223.01 95.8333 1252.97 97.9167L1284 100V0H1252.97C1223.01 0 1162.02 0 1101.03 0C1038.97 0 977.98 0 916.99 0C856 0 795.01 0 734.02 0C673.03 0 610.97 0 549.98 0C488.99 0 428 0 367.01 0C306.02 0 245.03 0 182.97 0C121.98 0 60.99 0 31.03 0H0Z"
              fill="currentColor"
            ></path>
          </svg>
          {/* <!--end: Divider shape--> */}

          <div className="container py-9 py-lg-11 position-relative">
            <div className="row pt-7">
              {/* <!--Testimonial column--> */}
              <div className="col-11 mx-auto col-lg-9" data-aos="fade-right">
                {/* <!--Testimonial card--> */}
                <div className="position-relative">
                  {/* <!--Testimonial text--> */}
                  <h2 className="display-8 font-serif mb-6">
                    "As a startup CTO, partnering with GPracta to set up our GCC
                    in Bangalore has been transformative. Their support and
                    expertise helped us efficiently establish and scale our
                    team, driving our growth and success. I highly recommend
                    GPracta for expanding your capabilities in India."
                  </h2>
                  {/* <!--Testimonial user info--> */}
                  <h6 className="mb-0">Binu Thayamkery</h6>
                  <small className="text-white-50">
                    Co-founder & CTO, Nepris
                  </small>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--end: Testimonial section--> */}
        <section className="position-relative overflow-hidden mt-lg-10">
          <div className="container pb-9 pb-lg-11 position-relative">
            <div className="row mb-5 mb-lg-9 justify-content-between align-items-end">
              <div className="col-lg-9 mx-auto text-center">
                <h2 className="display-6 mb-0" data-aos="fade-up">
                  Our Service Models for GCC
                </h2>
              </div>
            </div>
            <div className="row">
              {/* <!--Feature column--> */}
              <div
                className="col-12 col-lg mb-7 mb-lg-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="text-center position-relative">
                  <div className="position-relative">
                    <div className="mb-4 position-relative width-9x height-9x text-white flex-center overflow-hidden">
                      {/* <!--Icon bg shape--> */}
                      <svg
                        className="w-100 h-100 position-absolute text-primary start-0 top-0 bottom-0 end-0"
                        preserveAspectRatio="xMidYMid meet"
                        width="101"
                        height="101"
                        viewBox="0 0 101 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M49.8646 100.836C29.3177 100.628 11.4444 87.7261 4.54642 68.3967C-3.05192 47.1047 -1.4418 21.6257 16.8429 8.30273C35.2155 -5.08436 60.0472 1.06004 78.2224 14.7133C95.3636 27.5898 104.8 48.9948 98.0999 69.3432C91.4658 89.491 71.1012 101.051 49.8646 100.836Z"
                          fill="currentColor"
                        />
                      </svg>
                      <i className="bx bx-check fs-3 position-relative"></i>
                    </div>
                    <h5 className="mb-3">Hosted</h5>
                    <p className="mb-4 px-lg-5">
                      Engage GPracta to set up and manage your entire operations
                      with zero upfront investment. This model allows you to
                      start immediately and see benefits accrue quickly.
                    </p>
                    <a
                      href="/global-capability-centers"
                      className="link-hover-underline fw-semibold"
                    >
                      Learn More
                      <i className="bx bx-right-arrow-alt align-middle ms-1 lh-1 fs-5"></i>
                    </a>
                  </div>
                </div>
              </div>

              {/* <!--Feature column--> */}
              <div
                className="col-12 col-lg mb-7 mb-lg-0"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="text-center position-relative">
                  <div className="position-relative">
                    <div className="mb-4 position-relative width-9x height-9x text-white flex-center overflow-hidden">
                      {/* <!--Icon bg shape--> */}
                      <svg
                        className="w-100 h-100 position-absolute text-primary start-0 top-0 bottom-0 end-0"
                        preserveAspectRatio="xMidYMid meet"
                        width="101"
                        height="101"
                        viewBox="0 0 101 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M49.8646 100.836C29.3177 100.628 11.4444 87.7261 4.54642 68.3967C-3.05192 47.1047 -1.4418 21.6257 16.8429 8.30273C35.2155 -5.08436 60.0472 1.06004 78.2224 14.7133C95.3636 27.5898 104.8 48.9948 98.0999 69.3432C91.4658 89.491 71.1012 101.051 49.8646 100.836Z"
                          fill="currentColor"
                        />
                      </svg>
                      <i className="bx bx-layer fs-3 position-relative"></i>
                    </div>
                    <h5 className="mb-3">Build, Operate & Transfer (BOT)</h5>
                    <p className="mb-4 px-lg-5">
                      We set up, improve, and manage your business processes or
                      IT service delivery operations with the goal of
                      transferring the operations to you as a self-managed
                      center in the future.
                    </p>
                    <a
                      href="/global-capability-centers"
                      className="link-hover-underline fw-semibold"
                    >
                      Learn More
                      <i className="bx bx-right-arrow-alt align-middle ms-1 lh-1 fs-5"></i>
                    </a>
                  </div>
                </div>
              </div>

              {/* <!--Feature column--> */}
              <div
                className="col-12 col-lg-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="text-center position-relative">
                  <div className="position-relative">
                    <div className="mb-4 position-relative width-9x height-9x text-white rounded-3 flex-center overflow-hidden">
                      {/* <!--Icon bg shape--> */}
                      <svg
                        className="w-100 h-100 position-absolute text-primary start-0 top-0 bottom-0 end-0"
                        preserveAspectRatio="xMidYMid meet"
                        width="101"
                        height="101"
                        viewBox="0 0 101 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M49.8646 100.836C29.3177 100.628 11.4444 87.7261 4.54642 68.3967C-3.05192 47.1047 -1.4418 21.6257 16.8429 8.30273C35.2155 -5.08436 60.0472 1.06004 78.2224 14.7133C95.3636 27.5898 104.8 48.9948 98.0999 69.3432C91.4658 89.491 71.1012 101.051 49.8646 100.836Z"
                          fill="currentColor"
                        />
                      </svg>
                      <i className="bx bx-paper-plane fs-3 position-relative"></i>
                    </div>
                    <h5 className="mb-3">Captive</h5>
                    <p className="mb-4 px-lg-5">
                      For companies that intend to set up, operate, and manage
                      their centers from the beginning, GPracta provides
                      comprehensive assistance throughout the entire process.
                    </p>
                    <a
                      href="/global-capability-centers"
                      className="link-hover-underline fw-semibold"
                    >
                      Learn More
                      <i className="bx bx-right-arrow-alt align-middle ms-1 lh-1 fs-5"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--begin:feature image section--> */}
        <section className="position-relative overflow-hidden">
          {/* <!--Content container--> */}
          <div className="container py-9 py-lg-11 position-relative">
            <div className="row align-items-center">
              {/* <!--Get started column--> */}
              <div
                className="col-lg-6 col-xl-5 ps-lg-7 ps-5 pb-4 order-lg-last me-lg-auto mb-5 mb-lg-0 position-relative"
                data-aos="fade-down"
              >
                <div className="position-relative bg-body rounded-block rounded-bottom-start-0 rounded-top-end-0 shadow-lg overflow-hidden">
                  <div className="position-relative bg-body overflow-hidden">
                    {/* <!--Get started image--> */}
                    <img
                      src="assets/img/960x640/core-values.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 order-lg-last position-relative">
                {/* <!--Subtitle--> */}
                <div
                  className="d-flex align-items-center mb-3"
                  data-aos="fade-up"
                ></div>
                <h2 className="display-5 me-lg-n9 mb-4" data-aos="fade-down">
                  Guiding Principles for Excellence
                </h2>
                <p
                  className="w-lg-75 mb-5"
                  data-aos="fade-down"
                  data-aos-delay="100"
                >
                  Our core values emphasize customer respect, fostering an
                  inspiring and enjoyable workplace, and promoting eco-friendly
                  business practices.
                </p>
                <ul className="step mx-3 mx-sm-0 list-unstyled mb-0">
                  <li className="step-item" data-aos="fade-up">
                    <div className="step-row">
                      <span className="step-icon bg-primary-subtle text-primary">
                        {/* <!--ICON-DOT--> */}
                        <b>01</b>
                      </span>

                      <div className="step-content">
                        {/* <h6 className="mb-1"></h6> */}
                        <p className="mb-0">
                          Treat customers the way we'd like to be treated..
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!--/.step-item--> */}
                  <li className="step-item" data-aos="fade-up">
                    <div className="step-row">
                      <span className="step-icon bg-primary-subtle text-primary">
                        {/* <!--ICON-DOT--> */}
                        <b>02</b>
                      </span>

                      <div className="step-content">
                        {/* <h6 className="mb-1"></h6> */}
                        <p className="mb-0">
                          Create an environment where employees can think big,
                          have fun, and do good.
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!--/.step-item--> */}
                  <li className="step-item mb-0" data-aos="fade-up">
                    <div className="step-row">
                      <span className="step-icon bg-primary-subtle text-primary">
                        {/* <!--ICON-DOT--> */}
                        <b>03</b>
                      </span>

                      <div className="step-content">
                        <h6 className="mb-1"> </h6>
                        <p className="mb-0">
                          Elevating business with eco-friendly values.
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!--/.step-item--> */}
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!--end: features image section--> */}

        {/* <!--Call to action--> */}
        <section className="bg-gradient-primary text-white position-relative">
          {/* <!--Shape--> */}
          <svg
            className="position-absolute end-0 bottom-0 me-4 text-white h-100 w-auto"
            width="122"
            height="87"
            viewBox="0 0 122 87"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M72 0L122 86.6026H22L72 0Z"
              fill="currentColor"
            />
            <path
              opacity="0.1"
              d="M32.5 56L65 -2.38419e-07H0L32.5 56Z"
              fill="currentColor"
            />
          </svg>

          <div className="container py-9 py-lg-11 position-relative z-1">
            <div className="row align-items-center">
              <div className="col-lg-8 col-xl-9">
                {/* <!--Heading--> */}

                <h2 className="display-6">Connect with Us</h2>
                <p className="lead mb-5 mb-lg-0">
                  Ready to take your organization to the next level? Partner
                  with GPracta and unlock the full potential of your business
                  through a strategically designed and expertly managed Global
                  Capability Center. Contact us today to learn more about how we
                  can help you succeed in India.
                </p>
              </div>
              <div className="col-lg-4 col-xl-3 ms-lg-auto text-lg-end">
                {/* <!--Action--> */}
                <a
                  className="btn btn-white btn-lg btn-hover-arrow"
                  href="/contact-us"
                >
                  <span>Contact Us Now!</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!--/.section Call to action end--> */}
      </main>
    </>
  );
};

export default Home;
