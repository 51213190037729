import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header/header.jsx";
import Home from "./Pages/Home/home.js";
import { GlobalCapabilityCenter } from "./Pages/Services/global-capability-center.js";
import CenterOfExcellence from "./Pages/Services/center-of-excellence.js";
import ExecutiveSearch from "./Pages/Services/executive-search.js";
import ContactUs from "./Contact Us/contact-us.js";
import Admin from "./Admin/admin.js";
import AddBlog from "./Admin/add-blog.js";
import EditBlog from "./Admin/edit-blog.js";
import AllBlogs from "./Pages/Insights/allblogs.js";
import ManageAllBlogs from "./Admin/manage-all-blogs.js";
import GoogleOAuth from "./Admin/login.js";
import GPractaBlogDetails from "./Pages/Insights/gpracta-blog-details.js";
import NewsletterDetails from "./Pages/Insights/newsletter-details.js";
import ManageGPractaBlogDetails from "./Admin/manage-gpractablog-details.js";
import ManageNewsletterDetails from "./Admin/manage-newsletter-details.js";
import PageNotFound from "./pagenotfound.js";
import SearchComponent from "./Admin/SearchComponent.js";
import AddDataComponent from "./Admin/add-index.js";
import AddGPractaBlog from "./Admin/add-gpracta-blogs.js";
import EditNews from "./Admin/edit-news.js";
import ManageBlogs from "./Admin/manage-blogs.js";
import BlogDetails from "./Pages/Insights/blog-details.js";
import FAQ from "./FAQ/faq.js";
import Policy from "./Policy/policy.js";
import GPractaNews from "./Pages/Insights/insights.js";
import GPractaBlogs from "./Pages/Insights/gpracta-blogs.js";
import OurStory from "./Pages/Our Story/our-story.jsx";
import ReactGA from "react-ga4";

function App() {
  // Retrieve a value from session storage
  const storedValue = sessionStorage.getItem("login");

  const isaccess = storedValue === "krishnav@gpracta.com";

  ReactGA.initialize("G-ETTDBDLTBG");

  // Send pageview with a custom path
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: "GPracta Website",
  });

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header /> <Home />
            </>
          }
        />
        <Route
          path="/global-capability-centers"
          element={
            <>
              <Header /> <GlobalCapabilityCenter />
            </>
          }
        />
        <Route
          path="/center-of-excellence"
          element={
            <>
              <Header /> <CenterOfExcellence />
            </>
          }
        />
        <Route
          path="/executive-search"
          element={
            <>
              <Header /> <ExecutiveSearch />
            </>
          }
        />

        <Route
          path="/contact-us"
          element={
            <>
              <Header /> <ContactUs />
            </>
          }
        />
        <Route
          path="/manage-blogs"
          element={
            <>
              <Header /> <ManageBlogs />
            </>
          }
        />
        <Route
          path="/admin"
          element={
            <>
              <Header /> {isaccess ? <Admin /> : <PageNotFound />}
            </>
          }
        />

        <Route
          path="/manage-all-blogs"
          element={
            <>
              <Header />
              {isaccess ? <ManageAllBlogs /> : <PageNotFound />}
            </>
          }
        />

        <Route
          path="/add-blog"
          element={
            <>
              <Header /> {isaccess ? <AddBlog /> : <PageNotFound />}
            </>
          }
        />

        <Route
          path="/add-gpracta-blog"
          element={
            <>
              <Header /> {isaccess ? <AddGPractaBlog /> : <PageNotFound />}
            </>
          }
        />

        <Route
          path="/edit-blog/:id"
          element={
            <>
              <Header /> {isaccess ? <EditBlog /> : <PageNotFound />}
            </>
          }
        />

        <Route
          path="/edit-news/:id"
          element={
            <>
              <Header /> {isaccess ? <EditNews /> : <PageNotFound />}
            </>
          }
        />
        <Route
          path="/blogs"
          element={
            <>
              <Header /> <AllBlogs />
            </>
          }
        />

        <Route
          path="/news"
          element={
            <>
              <Header /> <GPractaNews />
            </>
          }
        />

        <Route
          path="/gpracta-blogs"
          element={
            <>
              <Header /> <GPractaBlogs />
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <Header />
              {storedValue ? <PageNotFound /> : <GoogleOAuth />}
            </>
          }
        />

        <Route
          path="/gpracta-blog-details"
          element={
            <>
              <Header />
              <GPractaBlogDetails />
            </>
          }
        />
        <Route
          path="/newsletter-details"
          element={
            <>
              <Header />
              <NewsletterDetails />
            </>
          }
        />

        <Route
          path="/manage-gpracta-blog-details"
          element={
            <>
              <Header />
              {isaccess ? <ManageGPractaBlogDetails /> : <PageNotFound />}
            </>
          }
        />

        <Route
          path="/manage-newsletter-details"
          element={
            <>
              <Header />
              {isaccess ? <ManageNewsletterDetails /> : <PageNotFound />}
            </>
          }
        />

        <Route
          path="/search-query"
          element={
            <>
              <Header />
              <SearchComponent />
            </>
          }
        />

        <Route
          path="/pagenotfound"
          element={
            <>
              <Header />
              <PageNotFound />
            </>
          }
        />

        <Route
          path="/add-data-component"
          element={
            <>
              <Header />
              {isaccess ? <AddDataComponent /> : <PageNotFound />}
            </>
          }
        />

        <Route
          path="/blog-details"
          element={
            <>
              <Header />
              <BlogDetails />
            </>
          }
        />

        <Route
          path="/faq"
          element={
            <>
              <Header />
              <FAQ />
            </>
          }
        />

        <Route
          path="/privacy-policy"
          element={
            <>
              <Header />
              <Policy />
            </>
          }
        />
        <Route
          path="/our-story"
          element={
            <>
              <Header />
              <OurStory />
            </>
          }
        />

        <Route
          path="*"
          element={
            <>
              <PageNotFound />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
