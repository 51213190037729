import React from "react";
import { useLocation } from "react-router-dom";

function BlogDetails() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  // Assuming you have a function to fetch blog details based on the id
  // You can replace this with your actual data fetching logic
  const fetchBlogDetails = async (id) => {
    try {
      // Fetch blog details using the id
      const response = await fetch(`/api/blog/${id}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching blog details:", error);
      return null;
    }
  };

  const [, setBlogDetails] = React.useState(null);

  React.useEffect(() => {
    if (id) {
      fetchBlogDetails(id).then((data) => {
        setBlogDetails(data);
      });
    }
  }, [id]);

  return (
    <>
      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>

      <main>
        <section id="article-header" className="position-relative">
          <div className="container pt-14 pb-9 pb-lg-11 position-relative">
            <article className="row pt-lg-7 pb-11">
              <div className="col-lg-10 col-xl-8">
                <div className="position-relative pb-3 pb-lg-0">
                  <div className="d-flex align-items-center w-100">
                    <a href="#!" className="badge bg-primary rounded-pill me-3">
                      Tech
                    </a>
                    <small className="text-body-secondary">02 May. 2021</small>
                  </div>

                  <div>
                    <h2 className="my-4 display-4">
                      This is a really catchy blog heading.
                    </h2>
                    <div className="d-flex pt-2 mb-0 small align-items-center">
                      <img
                        src="assets/img/avatar/6.jpg"
                        alt=""
                        className="width-3x height-3x rounded-circle me-2"
                      />
                      <span className="text-body-secondary d-inline-block">
                        By{" "}
                        <a href="#!" className="text-dark">
                          Andrew
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            {/* <!--/.article--> */}
          </div>

          {/* <!--Divider--> */}
          <svg
            className="position-absolute start-0 bottom-0"
            //   style="color: var(--bs-body-bg);"
            preserveAspectRatio="none"
            width="100%"
            height="120"
            viewBox="0 0 800 240"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M800 240H0L800 0V240Z" fill="currentColor" />
          </svg>
        </section>
        {/* <!--/.Article header-end--> */}

        <section className="position-relative border-bottom">
          <div className="container pb-9 pb-lg-11">
            <img
              src="assets/img/1200x600/4.jpg"
              alt=""
              className="img-fluid shadow-lg rounded-4 mb-7 mb-lg-9 position-relative mt-n14"
            />

            <div className="row">
              <div className="col-xl-9 mx-auto">
                <article className="article mb-9">
                  <p className="lead mb-4 dropcap">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Viverra nam libero justo laoreet. Suscipit
                    adipiscing bibendum est ultricies integer.
                  </p>
                  <p className="mb-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Viverra nam libero justo laoreet. Suscipit
                    adipiscing bibendum est ultricies integer quis auctor elit.
                    Mauris pellentesque pulvinar pellentesque habitant morbi.
                    Sapien nec sagittis aliquam malesuada. Purus sit amet
                    volutpat consequat mauris. Imperdiet sed euismod nisi porta
                    lorem mollis. Risus quis varius quam quisque id diam.
                    Habitasse platea dictumst vestibulum rhoncus est
                    pellentesque elit. Sit amet massa vitae tortor condimentum
                    lacinia quis vel eros. Diam vel quam elementum pulvinar
                    etiam non. Nisl rhoncus mattis rhoncus urna. Vel elit
                    scelerisque mauris pellentesque. Molestie a iaculis at erat
                    pellentesque adipiscing commodo elit at. Faucibus et
                    molestie ac feugiat sed lectus vestibulum mattis
                    ullamcorper.
                  </p>

                  <p className="mb-5">
                    Venenatis cras sed felis eget velit aliquet. Pharetra et
                    ultrices neque ornare aenean euismod elementum. Gravida
                    dictum fusce ut placerat. Facilisis mauris sit amet massa.
                    Nunc scelerisque viverra mauris in aliquam. In iaculis nunc
                    sed augue lacus. Pellentesque habitant morbi tristique
                    senectus et netus. Placerat duis ultricies lacus sed turpis
                    tincidunt. Vitae congue mauris rhoncus aenean vel elit
                    scelerisque mauris. Tincidunt lobortis feugiat vivamus at
                    augue eget arcu dictum. Amet porttitor eget dolor morbi non
                    arcu risus. Mi tempus imperdiet nulla malesuada
                    pellentesque. Lectus urna duis convallis convallis. Tellus
                    cras adipiscing enim eu turpis egestas pretium aenean
                    pharetra.
                  </p>
                  <h4 className="mb-4">A highlight text heading</h4>
                  <p>
                    Harum quidem rerum facilis est et expedita distinctio. Nam
                    libero tempore, cum soluta nobis est eligendi optio cumque
                    nihil impedit quo minus id quod maxime placeat facere
                    possimus, omnis voluptas assumenda est, omnis dolor
                    repellendus.
                  </p>
                  {/* <div className="row"> */}

                  <p className="mb-0">
                    Suscipit adipiscing bibendum est ultricies integer quis
                    auctor elit. Mauris pellentesque pulvinar pellentesque
                    habitant morbi. Sapien nec sagittis aliquam malesuada. Purus
                    sit amet volutpat consequat mauris. Imperdiet sed euismod
                    nisi porta lorem mollis. Risus quis varius quam quisque id
                    diam. Habitasse platea dictumst vestibulum rhoncus est
                    pellentesque elit. Sit amet massa vitae tortor condimentum
                    lacinia quis vel eros. Diam vel quam elementum pulvinar
                    etiam non. Nisl rhoncus mattis rhoncus urna. Vel elit
                    scelerisque mauris pellentesque. Molestie a iaculis at erat
                    pellentesque adipiscing commodo elit at. Faucibus et
                    molestie ac feugiat sed lectus vestibulum mattis
                    ullamcorper.
                  </p>
                </article>
                {/* <!--/.article--> */}

                <div className="d-flex justify-content-end align-items-center mb-9">
                  <div className="me-3 small text-body-secondary">
                    Share Article
                  </div>
                  <div>
                    <a href="#!" className="d-inline-block me-1">
                      <img
                        src="assets/img/brands/facebook.svg"
                        alt=""
                        className="width-2x height-2x hover-lift"
                      />
                    </a>
                    <a href="#!" className="d-inline-block me-1">
                      <img
                        src="assets/img/brands/twitter.svg"
                        alt=""
                        className="width-2x height-2x hover-lift"
                      />
                    </a>
                    <a href="#!" className="d-inline-block me-1">
                      <img
                        src="assets/img/brands/Linkedin.svg"
                        alt=""
                        className="width-2x height-2x hover-lift"
                      />
                    </a>
                  </div>
                </div>
                {/* <!--/.social--> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default BlogDetails;
