import React from "react";

const Policy = () => {
  return (
    <>
      <main>
        <section className="position-relative">
          <div className="container py-9 py-lg-11">
            <div className="row pt-4 pt-lg-9 justify-content-center">
              <div className="col-xl-9">
                <div className="shadow-lg rounded-4 px-4 px-lg-6 py-5 py-lg-7">
                  <div className="mb-4 pb-4 border-bottom">
                    <h2>Our Privacy policy</h2>
                    <p>
                      <small className="text-body-secondary">
                        Last Revised:
                      </small>{" "}
                      May 20, 2024
                    </p>
                  </div>

                  <p>
                    At GPracta.com, accessible from www.GPracta.com, one of our
                    main priorities is the privacy of our visitors. This Privacy
                    Policy document contains types of information that is
                    collected and recorded by GPracta.com and how we use it.If
                    you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.This
                    Privacy Policy applies only to our online activities and is
                    valid for visitors to our website with regards to the
                    information that they shared and/or collect in GPracta.com.
                    This policy is not applicable to any information collected
                    offline or via channels other than this website.
                  </p>

                  <h5 className="mb-4">Consent</h5>
                  <p>
                    By using our website, you hereby consent to our Privacy
                    Policy and agree to its terms.
                  </p>

                  <h5 className="mb-4">Information we collect</h5>
                  <p>
                    The personal information that you are asked to provide, and
                    the reasons why you are asked to provide it, will be made
                    clear to you at the point we ask you to provide your
                    personal information. If you contact us directly, we may
                    receive additional information about you such as your name,
                    email address, phone number, the contents of the message
                    and/or attachments you may send us, and any other
                    information you may choose to provide.
                  </p>
                  <p>
                    When you register for an Account or fill out a form to
                    contact us, we may ask for your contact information,
                    including items such as name, company name, address, email
                    address, and telephone number.
                  </p>

                  <h5 className="mb-4">How we use your information</h5>
                  <p>
                    We use the information we collect in various ways, including
                    to:
                  </p>
                  <ul className="lh-lg mb-5">
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>
                      Develop new products, services, features, and
                      functionality
                    </li>
                    <li>
                      Communicate with you, either directly or through one of
                      our partners, including for customer service, to provide
                      you with updates and other information relating to the
                      website, and for marketing and promotional purposes
                    </li>
                    <li>Send you emails</li>
                    <li>Find and prevent fraud</li>
                  </ul>
                  <h5 className="mb-4">Log Files</h5>

                  <p className="mb-5">
                    GPracta.com follows a standard procedure of using log files.
                    These files log visitors when they visit websites. All
                    hosting companies do this and a part of hosting service's
                    analytics. The information collected by log files include
                    internet protocol (IP) addresses, browser type, Internet
                    Service Provider (ISP), date and time stamp, referring/exit
                    pages, and possibly the number of clicks. These are not
                    linked to any information that is personally identifiable.
                    The purpose of the information is for analyzing trends,
                    administering the site, tracking user's movement on the
                    website, and gathering demographic information.
                  </p>
                  <h5 className="mb-4">Cookies and Web Beacons</h5>
                  <p>
                    Like any other website, GPracta.com uses 'Cookies'. These
                    cookies are used to store information including visitor's
                    preferences, and the pages on the website that the visitor
                    accessed or visited. The information is used to optimize the
                    user's experience by customizing our web page content based
                    on visitor's browser type and/or other information.
                  </p>
                  <h5 className="mb-4">
                    Advertising Partners Privacy Policies
                  </h5>
                  <p className="mb-5">
                    You may consult this list to find the Privacy Policy for
                    each of the advertising partners of GPracta.com. Third-party
                    ad servers or ad networks uses technologies like cookies,
                    JavaScript, or Web Beacons that are used in their respective
                    advertisements and links that appear on GPracta.com, which
                    are sent directly to users’ browser. They automatically
                    receive your IP address when this occurs. These technologies
                    are used to measure the effectiveness of their advertising
                    campaigns and/or to personalize the advertising content that
                    you see on websites that you visit. Note that GPracta.com
                    has no access to or control over these cookies that are used
                    by third-party advertisers.
                  </p>
                  <h5 className="mb-4">Third Party Privacy Policies</h5>
                  <p className="mb-5">
                    Under the CCPA, among other rights, California consumers
                    have the right to: Request that a business that collects a
                    consumer's personal data disclose the categories and
                    specific pieces of personal data that a business has
                    collected about consumers. Request that a business delete
                    any personal data about the consumer that a business has
                    collected. Request that a business that sells a consumer's
                    personal data, not sell the consumer's personal data. If you
                    make a request, we have two months to respond to you. If you
                    would like to exercise any of these rights, please contact
                    us.
                  </p>
                  <h5 className="mb-4">GDPR Data Protection Rights</h5>
                  <p>
                    We would like to make sure you are fully aware of all of
                    your data protection rights. Every user is entitled to the
                    following: The right to access - You have the right to
                    request copies of your personal data. We may charge you a
                    small fee for this service. The right to rectification - You
                    have the right to request that we correct any information
                    you believe is inaccurate. You also have the right to
                    request that we complete the information you believe is
                    incomplete. The right to erasure - You have the right to
                    request that we erase your personal data, under certain
                    conditions. The right to restrict processing - You have the
                    right to request that we restrict the processing of your
                    personal data, under certain conditions. The right to object
                    to processing - You have the right to object to our
                    processing of your personal data, under certain conditions.
                    The right to data portability - You have the right to
                    request that we transfer the data that we have collected to
                    another organization, or directly to you, under certain
                    conditions. If you make a request, we have two months to
                    respond to you. If you would like to exercise any of these
                    rights, please contact us.
                  </p>
                  <h5 className="mb-4">Children's Information</h5>
                  <p>
                    Another part of our priority is adding protection for
                    children while using the internet. We encourage parents and
                    guardians to observe, participate in, and/or monitor and
                    guide their online activity.GPracta.com does not knowingly
                    collect any Personal Identifiable Information from children
                    under the age of 13. If you think that your child provided
                    this kind of information on our website, we strongly
                    encourage you to contact us immediately and we will do our
                    best efforts to promptly remove such information from our
                    records.
                  </p>
                  <h5 className="mb-4">Contact us</h5>
                  <ul className="lh-lg">
                    <li>
                      by mail:{" "}
                      <strong>
                        <a href="mailto:giri@gpracta.com">giri@gpracta.com</a>
                      </strong>
                    </li>
                    <li>
                      by phone:{" "}
                      <strong>
                        <a href="#!">+91 97405 16800</a>
                      </strong>
                    </li>
                  </ul>
                  <small className="text-body-secondary">
                    We will respond with in 3-5 business days.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Policy;
