import React, { useState,useEffect } from 'react';
import searchService from './SearchQuery';

const SearchComponent = () => {
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await searchService.getAllData();
        setResults(data);
      } catch (error) {
        console.error("Error fetching all data:", error);
        setError("Failed to fetch data. Please try again later.");
        setResults([]); // Optionally clear results on error
      }
    };

    fetchData();
  }, []);

  const handleSearch = async (query) => {
    setQuery(query); // Store query in state
    if (!query.trim()) {
      // If the query is empty, consider reloading all data or leaving current all data view
      return;
    }
    try {
      const data = await searchService.search(query);
      setResults(data);
    } catch (error) {
      console.error("Error searching:", error);
      setError("Search failed. Please try again later.");
      setResults([]); // Clear results on error
    }
  };

  // const handleFetchAllData = async () => {
  //   try {
  //     const data = await searchService.getAllData();
  //     setResults(data);
  //   } catch (error) {
  //     console.error("Error fetching all data:", error);
  //     setError("Failed to fetch data. Please try again later.");
  //     setResults([]); // Clear results on error
  //   }
  // };

  // const handleSearch = async (query) => {
  //   if (!query.trim()) {
  //     // If query is empty, fetch all data instead
  //     await handleFetchAllData();
  //     return;
  //   }
  //   try {
  //     const data = await searchService.search(query);
  //     setResults(data);
  //   } catch (error) {
  //     console.error("Error searching:", error);
  //     setError("Search failed. Please try again later.");
  //     setResults([]); // Clear results on error
  //   }
  // };

  return (
    <div>
    <input
      type="text"
      placeholder="Enter search query"
      value={query}
      onChange={(e) => handleSearch(e.target.value)}
    />
    {error && <p>{error}</p>}
    <ul>
      {results.map((item, index) => (
        <li key={index}>{item._source.title} - {item._source.description}</li>
      ))}
    </ul>
  </div>
  );
};

export default SearchComponent;
