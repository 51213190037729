import React, { useState, useEffect } from "react";
import searchService from "../../Admin/SearchQuery";
import { Link } from "react-router-dom";
import Pagination from "../../Pagination";

const GPractaNews = () => {
  const [, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await searchService.getAllData();
        setResults(data);
        setError(null);
      } catch (error) {
        console.error("Error fetching all data:", error);
        setError("Failed to fetch data. Please try again later.");
        setResults([]);
      }
    };

    fetchData();
  }, []);

  const handleSearch = async (query) => {
    setQuery(query);
    if (!query.trim()) {
      // fetchData();
      return;
    }
    try {
      const data = await searchService.search(query);
      setResults(data);
      setError(null);
    } catch (error) {
      console.error("Error searching:", error);
      setError("Search failed. Please try again later.");
      setResults([]);
    }
  };

  // Calculate current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>

      {/* <!--Main content start--> */}
      <main>
        <section className="bg-default">
          <div className="container pt-14 pb-9 pb-lg-12">
            <div className="row pt-lg-7">
              <div className="col-xl-9">
                <ol className="breadcrumb mb-3">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Insights & News</li>
                </ol>
                <h1 className="mb-0 display-4">Insights & News</h1>
              </div>
            </div>
          </div>
        </section>

        <div className="position-relative">
          <div className="container py-9 py-lg-11">
            <div className="row">
              <div className="col-lg-3 col-md-4 mb-5 mb-md-0">
                <div className="pb-5">
                  <h5 className="title mb-3">Search</h5>
                  <form className="d-flex" role="search">
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Search Blog"
                      aria-label="Search"
                      style={{ width: "100%" }}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button type="submit" className="btn btn-primary btn-block">
                      Go
                    </button>
                  </form>
                </div>
                {/* <!--/.widget--> */}
                <div className="pb-5">
                  <h5 className="title mb-3">Recent Posts</h5>
                  {results.map((result, index) => {
                    return (
                      <article
                        className="d-flex mb-4 align-items-stretch"
                        key={result._id}
                      >
                        <div className="me-3">
                          <a href="#!" className="d-block">
                            <img
                              src="assets/img/960x900/1.jpg"
                              alt="article_thumbnail"
                              className="width-7x h-auto rounded-3 hover-shadow-lg hover-lift"
                            />
                          </a>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <a
                            href={`/blog-details?id=${result._id}`}
                            className="flex-grow-1 d-block"
                          >
                            <h6> {result._source.title}</h6>
                          </a>
                          <div className="d-flex pt-2 justify-content-between">
                            <small className="mb-0">
                              {/* {result._source.category}{" "} */}
                              {new Date(
                                `${result._source.posted_date}`
                                  .split("/")
                                  .reverse()
                                  .join("-")
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}{" "}
                            </small>
                            {/* <small className="text-body-secondary">
                               
                              </small> */}
                          </div>
                        </div>
                      </article>
                    );
                  })}
                </div>
                <div className="pb-5">
                  <h5 className="title mb-3">Tags</h5>
                  <a
                    href="/news"
                    className="badge text-body border badge-pill mb-1"
                  >
                    News
                  </a>
                  <Link
                    // href="/gpracta-blogs"
                    className="badge text-body border badge-pill mb-1"
                    disabled
                    style={{ cursor: "not-allowed" }}

                  >
                    Blogs
                  </Link>
                  <Link
                    // to="/"
                    className="badge text-body border badge-pill mb-1"
                    disabled
                    style={{ cursor: "not-allowed" }}
                  >
                    Newsletters
                  </Link>
                </div>
              </div>
              <div className="col-md-8 col-lg-9">
                <div className="pe-lg-4 pe-md-2">
                  {currentItems.map((result, index) => {
                    return (
                      <article
                        className="card align-items-stretch flex-md-row mb-4 mb-md-7 border-0 no-gutters"
                        data-aos="fade-up"
                        key={result._id}
                      >
                        <div className="col-lg-5">
                          <a
                            href="#!"
                            className="d-block rounded-5 overflow-hidden hover-shadow-lg hover-lift"
                          >
                            <img
                              src="assets/img/960x900/4.jpg"
                              alt=""
                              className="img-fluid rounded-3"
                            />
                          </a>
                        </div>
                        <div className="card-body d-flex flex-column col-auto p-md-0 ps-md-4">
                          <div className="d-flex mb-0 justify-content-between">
                            <span className="d-inline-flex align-items-center small">
                              <svg
                                className="bx bx-clock me-2 text-body-secondary"
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z"
                                ></path>
                                <path
                                  fillRule="evenodd"
                                  d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                                ></path>
                              </svg>
                              <span className="text-body-secondary">
                                {new Date(
                                  `${result._source.posted_date}`
                                    .split("/")
                                    .reverse()
                                    .join("-")
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}{" "}
                              </span>
                            </span>
                          </div>
                          <h3 className="mb-3 h2 mt-3">
                            <a
                              href={`/blog-details?id=${result._id}`}
                              className="flex-grow-1 d-block"
                            >
                              {result._source.title}
                            </a>
                          </h3>
                          <p className="mb-4 flex-grow-1 d-none d-lg-block">
                            {result._source.content_excerpt}
                          </p>
                          <div className="d-flex mb-0 small align-items-center">
                            <img
                              src="assets/img/avatar/3.jpg"
                              alt=""
                              className="avatar sm me-2 rounded-circle"
                            />
                            <span className="text-body-secondary d-inline-block">
                              By {result._source.author}
                            </span>
                          </div>
                        </div>
                        {/* <!--/.card-body--> */}
                      </article>
                    );
                  })}

                  {/* <!--/.article--> */}
                </div>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={results.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />

                {/* <!--/.Pagination-row--> */}
              </div>
              {/* <!--/.col--> */}
            </div>
            {/* <!--/.blog-row--> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default GPractaNews;
